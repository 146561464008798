import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { useMockedUser } from 'src/hooks/use-mocked-user';

import { useTranslate } from 'src/locales';
import { DistributorProfile } from 'src/auth/context/jwt/utils';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { t } = useTranslate();
  const { user } = useMockedUser();
  const DistributorProfile1 = DistributorProfile();
  let support;
  let tele;
  switch (DistributorProfile1) {
    case 0:
      support = 'support@idoose.com';
      tele = '+34 911 230 610';
      break;
    case 1:
      support = 'info@expofarm.es';
      tele = '+34 938 199 123';
      break;
    case 2:
      support = 'info@expofarm.es';
      tele = '+34 938 199 123';
      break;
    default:
      support = 'support@idoose.com';
      tele = '+34 911 230 610';
      break;
  }
  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar
            src="/assets/images/avatar/avatar_12.jpg"
            alt={user?.displayName}
            sx={{ width: 48, height: 48 }}
          />
          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            {t('menu.assist')}
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {t('menu.support')}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {support}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {tele}
          </Typography>

          {DistributorProfile1 === 0 && (
            <Button
              variant="outlined"
              size="small"
              href="https://crm.idoose.com/public/ticket"
              target="_blank"
              rel="noopener"
              startIcon={<Iconify icon="mingcute:tool-fill" width={18} />}
            >
              {t('home.open_ticket')}
            </Button>
          )}
        </Stack>

        <Button variant="contained" target="_blank" rel="noopener">
          V 5.3.38
        </Button>
      </Stack>
    </Stack>
  );
}

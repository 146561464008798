const en = {
  demo: {
    title: 'English',
    introduction:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },

  resetpass: {
    mesage: 'Forgot your password?',
    info: 'Enter the email address associated with your account and we will send you a link to reset your password.',
    reset: 'Reset',
    back: 'Back',
    sent: 'Request sent successfully',
    confirmation: 'We have sent a confirmation email to',
    check: 'Check your email',
    check1:
      'We have emailed a 6-digit confirmation code, please enter the code in the box below to verify your email.',
    verify: 'Verify',
    resend: 'Resend code',
    donthave: "Don't have the code? ",
    return: 'Return to Home',
    success: 'Request sent successfully!',
    success2: 'We have sent a 6-digit confirmation message to your email.',
    success3: 'Please enter the code in the box below to verify your email.',
    newpass: 'Update Password',
    confirmpass: 'Confirm New Password',
    pass: 'Password',
    checkpegatina: 'Check the physical sticker',
    checkpegatina2:
      'To ensure the security of your sensitive data, the 6-digit confirmation PIN is printed on the physical sticker. Enter that PIN in the box below to ensure access to confidential information. If you do not have the PIN, log in and it will not be necessary to enter it.',
    errorpin: 'Wrong pin',
  },
  newparameter: {
    back: 'Back',
    next: 'Next',
    scope: 'Parameter Scope',
    type: 'Parameter Type',
    config: 'Configuration',
    posology: 'Sell as prescribed in Posology',
    swap: 'Prescription Substitution',
    notdispense: 'Do Not Dispense',
    priority: 'Prioritize Highlighted Farmatic',
    add: 'Finish',
    priorityconfirm: 'From now on we will prioritize your Highlighted Medications.',
    posologyconfirm: 'From now on only what is prescribed in Posology will be sold.',
    selectsust: 'Prescription to Substitute',
    selectsust2: 'Prescription to Dispense.',
    notdispence2: 'Select the prescription you do not wish to dispense.',
    number: 'Number',
    scopetable: 'Affects',
    name: 'Name',
    typeparameter: 'Parameter Type',
    code: 'National Codes',
    patient: 'Patient',
    center: 'Center',
    pharmacy: 'Pharmacy',
    swaptype: 'Prescription Substitution',
    prioritytype: 'Prioritize Highlights',
    posologytype: 'Only as prescribed in Posology',
    notdispencetype: 'Do not dispense',
    no_financied: 'Only Financed',
    no_narcotic: 'Do not sell Narcotics',
    maxstock: 'Higher Stock',
    max_box_dps: 'Maximum Units',
    no_regex: 'No dispensation by "word"',
    order_lab: 'Prioritize Laboratory',
    check_stock: 'Sell with Stock',
    force_posology: 'Sell by Posology CN',
    laboratory: 'Laboratory List',
    replace_max_stock: 'Substitution by Maximum Stock',
    cst_change: 'Last Reload in Hopper',
  },
  table: {
    column: 'Columns',
    find: 'Find your column.',
    columntitle: 'Column Title',
    hyde: 'Unmark all',
    mark: 'Select all',
    filters: 'Filters',
    density: 'Display Density',
    density1: 'Compact',
    density2: 'Normal',
    density3: 'Large',
    export: 'Export',
    operators: 'Operators',
    contains: 'contains',
    equals: 'equals',
    start: 'starts with',
    end: 'ends with',
    empty: 'is empty',
    noempty: 'is not empty',
    value: 'Value',
    filtervalue: 'Filter Value',
    download: 'Download as CSV',
    print: 'Print',
    download2: 'Download as Excel',
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Show Columns',
    columnMenuManageColumns: 'Manage Columns',
    columnMenuFilter: 'Filter',
    columnMenuHideColumn: 'Hide Column',
    columnMenuUnsort: 'Unsort',
    columnMenuSortAsc: 'Sort Ascending',
    columnMenuSortDesc: 'Sort Descending',
  },
  menu: {
    general: 'MENU',
    order: 'Order Management',
    'historic Orders': 'Historic',
    catalog: 'Medications',
    patient: 'Patients',
    posology: 'Posology',
    newpro: 'Production',
    Laboratorys: 'Laboratories',
    Config: 'Configuration',
    management: 'ADMINISTRATION',
    user: 'Users',
    profile: 'My Profile',
    list: 'User Management',
    myinstallation: 'My Installation',
    subscription: 'My Pharmacy',
    invoice: 'Invoice',
    centers: 'Centers',
    centersmanage: 'Center Management',
    virtual: 'IA Mery',
    available: 'Purchase Orders',
    parameters: 'Parameters',
    listrx: 'Prescriptions per Patient',
    reports: 'Electronic Prescription',
    traceability: 'Traceability',
    alerts: 'Alerts',
    history: 'History',
    medpatient: 'Medication per Patient',
    merge: 'Posology Cross',
    take: 'Medication Delivery',
    import: 'Import',
    inicio: 'Home',
    upload: 'Upload File',
    parametersimport: 'Import Parameters',
    task: 'Scheduled Tasks',
    electronic: 'Health Cards',
    availableia: 'Available',
    match: 'Posology / Prescription Cross',
    sellparameters: 'Sale Parameters',
    form0: 'Reports',
    form1: 'Production Report',
    form2: 'Medication per Patient',
    form3: 'Traceability',
    chat: 'Chat',
    calendar: 'Calendar',
    support: 'Technical Support',
    assist: 'Assistance',
  },
  dropdown: {
    Home: 'Home',
    Profile: 'Profile',
    Settings: 'Settings',
    logout: 'Logout',
  },
  alert: {
    success: 'Access granted',
    newuser: 'New User',
    verified: 'Verified',
    yes: 'Yes',
    no: 'No',
    edit: 'Edit',
    deleteuser: 'Delete User',
    confirmation: 'Are you sure you want to delete this/these Users?',
  },
  myuser: {
    general: 'General',
    pass: 'Change Password',
    allow: 'Allowed',
    sice: 'Maximum Size of',
    save: 'Save Changes',
    about: 'Comment',
    old: 'Current Password',
    new: 'New Password',
    mustbe: 'Password must be at least 6 characters long.',
    confirm: 'Confirm New Password',
    banned: 'Disabled',
    applyban: 'Apply account disabling.',
    savechanges: 'Save Changes',
    upload: 'Upload File',
    photo: 'Upload Photo',
    update: 'Update Photo',
  },
  register: {
    mesage: 'Quick, simple, and intuitive.',
    intro: 'Create your account and start enjoying our solutions.',
    intro2: "It's very simple!",
    name: 'Name and Surname',
    lastname: 'Last Name',
    namepharma: 'Facility Name',
    create: 'Register',
    already: 'Already have an account? ',
    firstnamerec: 'First name is required',
    lastnamerec: 'Last name is required',
    emailrec: 'Email is required',
    passrec: 'Password is required',
  },
  centers: {
    list: 'Center List',
    title: 'Center Details',
    nif: 'NIF',
    date: 'Registration Date',
    production: 'Production Mode',
    machinepro: 'Selected Device',
    print1: 'Print non-packable items.',
    print2: 'Print by moments.',
    print3: 'Print the If necessary.',
    code: 'By drug code',
    code2: 'By packaging family',
    code3: 'By biological families',
    change: 'Do you want to change Center?',
    changeinstallation: 'Do you want to change Facility?',
    sure: 'Are you sure you want to change center?',
    acept: 'Accept',
    addnew: 'New Default Pattern',
    namenewpred: 'Pattern Name',
    syncdate: 'Synchronize Electronic Prescription End Date',
  },
  listmedicine: {
    medication: 'Medication',
    search: 'Search',
    filter: 'Medication Filter',
    all: 'All',
    tipemedication: 'Medication Type',
    familya: 'Packaging Family',
    familyb: 'Biological Family',
    inactive: 'Inactive',
    active: 'Active',
    ficha: 'Medication Details',
    brand: 'Brand',
    generic: 'Generic',
    needrecipe: 'Recipe required',
    code: 'Code',
    name: 'Name',
    codefamily: 'Biological Family Code',
    namefamily: 'Biological Family Name',
    units: 'Box Units',
    atc: 'ATC Code',
    packable: 'Packable',
    psicotropic: 'Psychotropic',
    narcotic: 'Narcotic',
    warning: 'Precautions',
    replaceable: 'Replaceable',
    clinic: 'Clinical Packaging',
    use: 'Hospital Use',
    comer: 'Marketed',
    tld: 'TLD',
    para: 'Para-pharmacy',
    control: 'Special Medical Control',
    drive: 'Driving Effects',
    orphan: 'Orphan',
    recipe: 'Recipe',
    bio: 'Bio Similar',
    save: 'Save',
    cancel: 'Cancel',
  },
  listpatient: {
    title: 'Patient List',
    search: 'Search',
    active: 'Active',
    ficha: 'Patient Details',
    name: 'Name',
    dni: 'ID Number',
    born: 'Date of Birth',
    ss: 'Social Security Number',
    phone: 'Phone',
    sex: 'Gender',
    female: 'Female',
    male: 'Male',
    bed: 'Bed',
    region: 'Region',
    room: 'Room',
    address: 'Address',
    country: 'Country',
    city: 'City',
    cp: 'Postal Code',
    mail: 'Email',
    save: 'Save',
    cancel: 'Cancel',
    sync: 'Sync',
    journal: 'Medication History',
    electronic: 'Electronic Prescription',
    group: 'Group',
    doctorname: "Doctor's name",
  },
  production: {
    status: 'Status',
    title: 'New Production',
    code: 'Code',
    datecreate: 'Creation Date',
    dateproduction: 'Production Date',
    lastprox: 'Next/Last Dispensation',
    initialdose: 'Initial/Last Dispensation',
    dateend: 'End Date',
    cancel: 'Cancel',
    pendent: 'Pending',
    working: 'In progress',
    produced: 'Produced',
    deleted: 'Deleted',
    repackaging: 'Repackaging Production',
    pouch: 'Number of Doses',
    dose: 'Units per Dose',
    printlabel: 'Print Labels',
    labelsearch: 'Search by Name',
    cretit_none: 'Insufficient credits',
    cretit_none1:
      'You do not have enough credits to complete this production. You can subscribe to a monthly plan to receive credits automatically, or if you have purchased original consumables, enter the serial code found on the card included with your order.',
    cretit_none2: 'Please top up your account to continue.',
    cretit_none3: 'Current credits',
    cretit_none4: 'Credits needed',
    cretit_none5: 'Credits',
  },
  dashboard: {
    title: 'Welcome back 👋',
    titlecomment:
      'With our advanced AI, we provide the safest and most reliable service on the market.',
    discover: 'Discover more',
    patients: 'Patients',
    medication: 'Active Medication',
    production: 'Productions',
    caducity: 'Medication nearing expiration',
    data: 'Last Production Data: ',
    total: 'Total Dispensed:',
    packable: 'Packable',
    unpackable: 'Non-packable',
    dispensed: 'Dispensed',
    activpatient: 'Active Patients',
    activposology: 'Active Treatments',
    totalcount: 'Units Dispensed This Month',
    type: 'Characteristics',
    if_needed: 'If needed',
    iaquality: 'AI Quality',
    merge: 'Correct',
    rxx: 'No Prescription',
    posology: 'No Posology',
    reviwer: 'Review',
    dep: 'Deposit',
    ranking: 'TOP Changes',
  },
  posology: {
    force_print: 'Print Only',
    no_sync: 'Protected',
    title: 'Posology',
    search: 'Search',
    active: 'Active',
    inactive: 'Inactive Medication',
    save: 'Save',
    cancel: 'Cancel',
    packable: 'Packable',
    unpackable: 'Non-packable',
    start: 'Start Date',
    end: 'End Date',
    dose: 'Dose',
    period: 'Days Interval',
    cronic: 'Chronic',
    days: 'Days',
    hour: 'Hour Interval',
    starthour: 'Start Time',
    months: 'Months',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    week: 'Weekly',
    ciclica: 'Cyclic Days',
    ciclicb: 'Cyclic Hours',
    especilavar: 'Variable',
    month: 'Monthly',
    aparte: 'Packaged Separately',
    optional: 'If Needed',
    irreplaceable: 'Irreplaceable',
    moment: 'Moment',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    add: 'Add Dose',
    name: 'Dose Name',
    selecttitle: 'Medication Selection',
    select: 'Select',
    addtoma: 'Add',
    cannot: 'You cannot save an empty pattern.',
    confirmation: 'Confirmation',
    confirmationtext: 'Are you sure you want to delete this treatment?',
    confirmationdelete: 'Delete',
    update: 'Changes saved successfully.',
    error: 'Error deleting posology.',
    erroradd: 'Error adding posology.',
    addsuccess: 'Added successfully.',
    deletesuccess: 'Deleted successfully.',
    errordate: 'Start Date cannot be earlier than today.',
    exist: 'Dose already exists.',
    periodless: 'Interval cannot be less than days.',
    daysless: 'Days cannot be less than 1.',
    hourless: 'Hours cannot be less than 1.',
    hourerror: 'Interval cannot be more than 24 hours.',
    dayerror: 'Day cannot be more than 31 or less than 0.',
    delete: 'Delete Posology',
    note: 'Notes',
    interaction: 'Pharmacological Interactions',
    deletedose: 'Do you want to delete the selected treatment?',
  },
  login: {
    mesage: 'Hello, welcome',
    introduction:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    signin: 'Sign in to IDOOSE',
    details: 'Enter your details below.',
    inicio: 'Sign In',
    agree: 'By signing up, I agree to the ',
    terms: 'Terms of Service',
    privacy: 'Privacy Policy',
    and: ' and ',
    account: 'New User? ',
    started: 'Create an account',
    email: 'Email',
    password: 'Password',
    rememberme: 'Remember Me',
    forgotpassword: 'Forgot Your Password?',
    requiremail: 'Email is required',
    requirepass: 'Password is required',
    requirement: 'By registering, I accept the ',
    requirement2: ' Terms and Conditions of Use',
    requirement3: ' and the ',
    requirement4: 'Privacy Policy',
  },

  virtual: {
    list: 'Prescription List',
    new: 'New Prescription',
    active: 'Active',
    expired: 'Expired',
    expiredate: 'Expiration Date',
    renewdate: 'Renewal Date',
    raw: 'Band / Chip / TSI / Prescription Number',
    order: 'Barcode (CAT only)',
    review: 'Review',
    assignment: 'Code Assignment',
    sell: 'Sale',
    create: 'Create Order',
    title: 'Scheduled Tasks',
    number: 'Order Number',
    status: 'Status',
    date: 'Start Date',
    patients: 'Number of Patients',
    titleparameters: 'Sale Parameters',
    confirm: 'Do you want to delete the selected prescription?',
    deletesinc: 'Do you want to delete the selected synchronization?',
    productionform: 'Production Report',
    prepareinform: 'Prepare Report',
    export: 'Export to Excel',
  },
  trazability: {
    ema: 'European Medicines Agency (EMA)',
    text: "The European Medicines Agency (EMA) is a decentralized agency of the European Union (EU) responsible for the scientific evaluation, supervision, and monitoring of the safety of medicines in the EU. EMA is a network organization whose activities involve thousands of experts from across Europe. These experts carry out the work of EMA's scientific committees.",
    discover: 'Discover EMA',
    detections: 'Detected Alerts',
    eligible: 'Eligible Alerts',
    lastupdate: 'Last Update',
    totals: 'Total Medication Verified',
  },
  reportpatientproductionlist: {
    lng_title: 'PRODUCED PATIENTS REPORT',
    lng_titlelistcenter: 'PATIENT LIST',
    lng_phone: 'Phone',
    lng_mail: 'Email',
    lng_num_production: 'Production Number',
    lng_center: 'Center',
    lng_date_int: 'Start Date',
    lng_date_end: 'End Date',
    lng_code: 'Code',
    lng_name: 'Name',
    lng_send: 'Send',
    lng_reception: 'Reception',
    lng_date: 'Date',
    lng_no_medication: 'Without Mediation',
    lng_reviwer: 'Reviewed by:',
    lng_produced: 'Produced by:',
  },
  reportmedicineproductionlist: {
    lng_title: 'GROUPED MEDICATION REPORT',
    lng_title2: 'REFILL REPORT',
    lng_title3: 'MEDICATION PER PATIENT REPORT',
    lng_phone: 'Phone',
    lng_mail: 'Email',
    lng_num_production: 'Production Number',
    lng_center: 'Center',
    lng_date_int: 'Start Date',
    lng_date_end: 'End Date',
    lng_code: 'Code',
    lng_name: 'Name',
    lng_qty: 'Quantity',
    lng_yes: 'Yes',
    lng_no: 'No',
    lng_packable: 'Packable',
    lng_date: 'Date',
    lng_dev: 'Hopper',
    lng_lot: 'Lot',
    lng_exp: 'Expiration',
    lng_qty2: 'Remaining',
  },
  reportposologyproductionlist: {
    lng_title_not_packing: 'NON-PACKABLE MEDICATION',
    lng_title_packing: 'PACKABLE MEDICATION',
    lng_mo2: 'MON',
    lng_tu2: 'TUE',
    lng_we2: 'WED',
    lng_th2: 'THU',
    lng_fr2: 'FRI',
    lng_sa2: 'SAT',
    lng_su2: 'SUN',

    lng_title: 'PHARMACOLOGICAL PLAN',
    lng_phone: 'Phone',
    lng_mail: 'Email',
    lng_date: 'Date',
    lng_num_patient: 'Patient Number',
    lng_name_patient: 'Name',
    lng_dni: 'ID',
    lng_ss: 'SS Number',
    lng_code_medicine: 'Code',
    lng_name_medicine: 'Medicine Name',
    lng_posology: 'Posology',
    lng_date_int: 'Start Date',
    lng_date_end: 'End Date',
    lng_cronic: 'Chronic',
    lng_mo: 'M',
    lng_tu: 'T',
    lng_we: 'W',
    lng_th: 'Th',
    lng_fr: 'F',
    lng_sa: 'S',
    lng_su: 'Su',
    lng_day: 'Days',
    lng_month: 'Months',
    lng_ja: 'JA',
    lng_fe: 'FE',
    lng_ma: 'MA',
    lng_ap: 'AP',
    lng_my: 'MY',
    lng_jn: 'JN',
    lng_jl: 'JL',
    lng_au: 'AU',
    lng_se: 'SE',
    lng_oc: 'OC',
    lng_no: 'NO',
    lng_de: 'DE',
    lng_month_all: 'All Months',
    lng_hour: 'Every',
    lng_hour_x: 'Hours From',
  },

  reportchangeslist: {
    lng_title_not_packing: 'NON-PACKABLE MEDICATION',
    lng_title_packing: 'PACKABLE MEDICATION',
    lng_mo2: 'MON',
    lng_tu2: 'TUE',
    lng_we2: 'WED',
    lng_th2: 'THU',
    lng_fr2: 'FRI',
    lng_sa2: 'SAT',
    lng_su2: 'SUN',

    lng_title: 'CHANGES REPORT',
    lng_phone: 'Phone',
    lng_mail: 'Email',
    lng_date: 'Date',
    lng_num_patient: 'Patient Number',
    lng_name_patient: 'Name',
    lng_dni: 'ID',
    lng_ss: 'SS Number',
    lng_code_medicine: 'Code',
    lng_name_medicine: 'Medicine Name',
    lng_posology: 'Posology',
    lng_date_int: 'Start Date',
    lng_date_end: 'End Date',
    lng_cronic: 'Chronic',
    lng_mo: 'M',
    lng_tu: 'T',
    lng_we: 'W',
    lng_th: 'Th',
    lng_fr: 'F',
    lng_sa: 'S',
    lng_su: 'Su',
    lng_day: 'Days',
    lng_month: 'Months',
    lng_ja: 'JA',
    lng_fe: 'FE',
    lng_ma: 'MA',
    lng_ap: 'AP',
    lng_my: 'MY',
    lng_jn: 'JN',
    lng_jl: 'JL',
    lng_au: 'AU',
    lng_se: 'SE',
    lng_oc: 'OC',
    lng_no: 'NO',
    lng_de: 'DE',
    lng_month_all: 'All Months',
    lng_hour: 'Every',
    lng_hour_x: 'Hours From',
  },

  sync: {
    new: 'Add Synchronization',
  },

  reportpatientjournallist: {
    lng_title: 'PATIENT DEBT REPORT',
    lng_phone: 'Phone',
    lng_mail: 'Email',
    lng_date: 'Date',
    lng_num_patient: 'Patient Number',
    lng_name_patient: 'Name',
    lng_dni: 'ID',
    lng_ss: 'SS Number',
    lng_code_medicine: 'Code',
    lng_name_medicine: 'Medicine Name',
    lng_prod: 'Produced',
    lng_seller: 'Sold',
    lng_aport: 'Supplied',
    lng_total: 'Total',
  },

  reportpatientconsent: {
    lng_anx_1: "I wish to participate in the pharmacy's personalized dosing systems program",
    lng_anx_2:
      'I understand that this program consists of the packaging of medication in a personalized dosing system.',
    lng_anx_3:
      'I participate voluntarily in this program and may stop participating in this program at any time simply by revoking this consent by completing the revocation section.',
    lng_anx_4:
      'Participating in this project implies, if done from commercial packaging, leaving the medications in their original packaging at the pharmacy where it is the responsibility of the pharmacist to properly safeguard and preserve them.',
    lng_anx_5: 'I commit to:',
    lng_anx_51: 'Inform the pharmacist of any changes in my medication.',
    lng_anx_52: 'Comply with the conservation and safety conditions of the blister.',
    lng_anx_53:
      'Deliver the empty devices from previous weeks for compliance verification and possible misuse errors.',
    lng_anx_6: 'The pharmacist commits to:',
    lng_anx_61: 'Inform me of any problems related to the medication that may be detected.',
    lng_anx_62:
      'Provide me with the necessary information for the correct use of personalized dosing devices and medications themselves.',
    lng_anx_7:
      'I authorize the pharmacist to process my personal data necessary for participation in the program*.',
    lng_consent: 'INFORMED CONSENT',
    lng_date: 'Date',
    lng_dni: 'ID',
    lng_don_d: 'Mr./Ms.',
    lng_lopd_1:
      '*The personal data provided for inclusion in the personalized dosing systems program will be included in a file called CDS, duly registered with the Spanish Data Protection Agency, whose responsible is the pharmacy',
    lng_lopd_2:
      " has all the necessary security measures according to the Data Protection Law, will be treated solely for the purpose of managing the patient's personalized dosing devices, the data may be transferred or communicated to healthcare professionals, family members, and/or caregivers related to the patient in order to corroborate the treatment and/or improve adherence to it, under no circumstances will they be used for advertising or commercial promotion purposes, they are subject to the pharmacist's professional secrecy. Persons whose data are registered in these files have the right to access, rectify, or cancel them, when they consider it appropriate by contacting this same pharmacy in the legally provided terms.",
    lng_mail: 'Email',
    lng_name_d: 'Patient/Legal Representative',
    lng_name_patient: 'Name',
    lng_name_ph_d: 'Pharmacist',
    lng_name_ph_sin_d: "Pharmacist's Signature",
    lng_name_sin_d: "Patient/Legal Representative's Signature",
    lng_num_patient: 'Patient Number',
    lng_phone: 'Phone',
    lng_ss: 'SS Number',
    lng_title: 'PATIENT INFORMED CONSENT',
  },
  Bob: {
    ordernumber: 'Order Number',
    status: 'Status',
    process: 'Process',
    Startorder: 'Start Order',
    createdate: 'Creation Date',
    selling: 'Selling',
    reviewing: 'Reviewing',
    wait: 'Waiting',
    sevem: 'SEVEM',
    patient: 'Patient',
    drug: 'Drug',
    recipe: 'Electronic Prescription',
    initialdate: 'Treatment Start Date',
    expiredate: 'Expiration Date',
    posology: 'Posology',
    scan: 'Scan Code',
    dep: 'Deposit',
    without1: 'No Posology',
    without2: 'No Prescription',
    reviwer: 'Review',
    correct: 'Correct',
    lot: 'Lot',
    neworder: 'Create Order',
    deleteorder: 'Do you want to delete the selected work order?',
    numberorder: 'Order Number:',
    history: 'Traceability History',
    scancodes: 'Code Assignment',
    forceall: 'Force All to CN',
    last: 'Last Update',
    deleteparameter: 'Do you want to delete the selected parameter?',
    sellformtitle: 'SALES REPORT',
  },

  official: {
    error: 'Document not available.',
  },

  display: {
    prospectus: 'Prospectus',
    technical: 'Technical Data Sheet',
    unpackable: 'Not Packable',
  },

  notfound: {
    sorry: 'Tome not found.',
    sorry2: "Sorry, we couldn't find the page you're looking for.",
    home: 'Home Page',
  },

  editproduction: {
    edit: 'Production Editing',
    confirm: 'Are you sure you want to modify the production?',
    home: 'Home Page',
    predose: 'Default Dosages',
    addnewpre: 'New Default Dose',
    nametome: 'Dose Name',
    hourtome: 'Dose Time',
    confirmationtext: 'Are you sure you want to delete this default dose?',
  },

  blisterpro: {
    process: 'PROGRESS',
    sorry2: "Sorry, we couldn't find the page you're looking for.",
    home: 'Home Page',
  },

  various: {
    exhausted: 'Exhausted',
    datapatient: 'Patient Data',
    center: 'Center Data',
    expiredon: 'Expiration Date:',
    synclist: 'Synchronized Items',
    debt: 'Debt',
    drug: 'Drug',
    produced: 'Produced',
    sell: 'Sold',
    provided: 'Provided',
    total: 'Total',
    date: 'Date',
    Details: 'Details',
    event: 'Event',
    units: 'Units',
    production: 'Production',
    sell2: 'Sold',
    add: 'Add Contribution',
    historic: 'History',
    historic2: 'Traceability History',
    ns: 'Serial Number',
    available: 'Available',
    next: 'Coming Soon',
    recomended: 'Recommended',
    notrecomended: 'Not Recommended',
    register: 'Delivery Register',
    registerdate: 'Date of Administration',
    from: 'From',
    to: 'To',
    datapicker: 'Report Dates',
    download: 'Download',
    tpills: 'Total Units',
    tneed: 'Remaining',
    rbox: 'Boxes',
    result: 'Results',
    predetermlist: 'Default Dosage List',
    deletepredet: 'Do you want to delete the selected default dosage?',
    no_verifi: 'Not Verified',
    verifi: 'Verified',
    installation: 'My Installation',
    exportamts: 'Export ATMS',
    confirmdose: 'Do you want to register the dosage?',
    whynot: 'Reason for Cancellation',
  },

  tooltip: {
    refill: 'Refill Report',
    label: 'Print Labels',
    edit: 'Edit Production',
    grouped: 'Grouped Medication Report',
    report1: 'Patient-Medication Report',
    delivery: 'Delivery Note',
    archived: 'Archived',
    delete: 'Delete Production',
    generateproduction: 'Generate Production',
    repackaging: 'Repackaging Production',
    start: 'Start Production',
    list: 'Patient List',
    add: 'New Patient',
    listposology: 'Posology by Patient',
    official: 'Official Posology Report',
    activate: 'Activate/Deactivate WhatsApp',
    consent: 'Informed Consent',
    alllabel: 'All',
    changes: 'Changes Report',
    sevem: 'Complete Sale',
    new: 'New Task',
    copy: 'Copy',
    insert: 'Insert',
    newparameter: 'New Parameter',
    center: 'New Center',
  },

  import: {
    e_title: 'Attention! The imported file could be incorrect',
    e_dialog: 'It seems that we have imported a file from the residence:',
    e_dialog1: 'instead of the residence:',
    e_dialog2: 'Please, review and verify the situation.',
    i_title: 'Import File',
    i_type: 'File Format',
    i_drop: 'Select File',
    i_dropb: 'You can drag and drop the file here or browse your computer to select it.',
    0: 'Resiplus (*.txt)',
    1: 'Resiplus Multi (*.txt)',
    2: 'Resiplus (*.xls)',
    3: 'Resiplus Multi (*.xls)',
    4: 'Sanitas (*.pdf)',
    5: 'DomusVi (*.txt)',
    6: 'DomusVi (*.pdf)',
    7: 'Pill Device (*.json)',
    8: 'Cretem Atms (*.xml)',
    9: 'Cretem Atms Multi (*.zip)',
    10: 'Jvm Oncube (*.txt)',
    11: 'Jvm Oncube Multi (*.zip)',
    12: 'Ecap (*.txt)',
  },
  module_: {
    title_: 'Module not available with the contracted license',
    subtitle_: 'Contact the sales department to request a demonstration!',
    return_: 'Return to App',
  },
  permit: {
    permit: 'Manage Permissions',
  },
  permissionsgroup: {
    1: 'Medication Catalog',
    2: 'Patient Management',
    3: 'Patient Posology',
    4: 'Production Module',
    5: 'Imports',
    6: 'Mery AI',
    7: 'Traceability',
    8: 'User Administration',
    9: 'Center Administration',
    10: 'Manage Pharmacy Data',
    11: 'Medication Delivery',
    12: 'Reports',
  },
  permissions: {
    1: 'Activate Medication Catalog',
    2: 'Modify Medications',
    3: 'View Patient Data',
    4: 'Modify Patient Data',
    5: 'View Electronic Prescription',
    6: 'Add or Delete Electronic Prescription',
    7: 'View Synchronization',
    8: 'Add or Delete Synchronizations',
    9: 'View Patient Consumptions',
    10: 'Modify Patient Consumptions',
    11: 'Activate Posology',
    12: 'Modify Posology',
    13: 'Activate Production Module',
    14: 'Create Production',
    15: 'Send Production / Manual Blister Section',
    16: 'Edit Production',
    17: 'Delete Production',
    18: 'Activate Import Module',
    19: 'Import Files into the System',
    20: 'Enable Mery AI',
    21: 'View Scheduled Tasks',
    22: 'Add Tasks',
    23: 'View Health Cards',
    24: 'Insert Cards',
    25: 'View Available',
    26: 'View Posology / Prescription Intersection',
    27: 'Activate Sales Parameters',
    28: 'Create Sales Parameter',
    29: 'Activate Patients Section',
    30: 'Enable Takes Control Module',
    31: 'Medication Delivery to Patients',
    32: 'Activate Traceability',
    33: 'Activate User Administration',
    34: 'Modify Users',
    35: 'Activate Center Management',
    36: 'Modify Centers',
    37: 'Activate My Installation',
    38: 'Modify My Installation',
    39: 'Activate Reports',
    40: 'Production Report',
  },
  control_takes: {
    1: 'Not Required',
    2: 'Medication Missing',
    3: 'Medication Damaged',
    4: 'Patient Refusal',
    5: 'Other',
    cause_not_take: 'Reason',
    6: 'Wrong Time',
    7: 'Medication Change',
    8: 'Add',
    9: 'Remove',
    10: 'Register Take',
  },
  home: {
    home: 'Home',
    title: 'Simplify Your Medication',
    titletex:
      'Welcome to the forefront of medication management. Our Custom Dosage Systems (CDS) Software redefines the way you engage with your health. Forget about complications in preparing your medications. With our intuitive and personalized solution, adherence to treatment becomes a simple and efficient experience.',
    available: 'Available on',
    benefits: 'CDS Benefits',
    titlebenefit1: 'Reminder Alert',
    benefit1:
      'Optimize your routine with our app that provides precise reminders for each dose. Forget about worries about forgetting and ensure your well-being. Also, check incompatibilities and interactions between your medications for complete and safe health management.',
    titlebenefit2: 'Personalized Service',
    benefit2:
      'Experience a new standard of comfort in your health care. With IDOOSE, you will receive your medication in meticulously prepared doses by highly trained pharmacy professionals. We are dedicated to providing you with a hassle-free experience, bringing quality and simplifying each dose.',
    titlebenefit3: 'We Prepare Your Medication',
    benefit3:
      'Say goodbye to the complication of preparing boxes. With our system of correlative unit doses, taking your medication is as easy as open and go. Each dose contains detailed information, including the prospectus, to provide you with comfort and clarity at every step of your treatment.',
    appidoose: 'With IDOOSE you will never forget your Medication.',
    appidoosetext:
      'Download the app, register and we will put you in contact with your nearest Pharmacy.',
    listposology: 'Posology per Patient',
    design: 'Designed for you',
    partner: 'Want to collaborate with us?',
    partner2: 'BECOME A PARTNER',
    partnertext:
      'We stand out as undisputed leaders in the application of artificial intelligence in the Custom Dosage System (CDS). By choosing us, you will ensure the efficiency of your productions and completely eliminate any risk of error in the production process. Trust our expertise to take your operations to the highest level of precision and safety.',
    prices: 'PRICES AND PLANS',
    licence: 'License',
    patient: 'Patient Management',
    production: 'Production Module',
    import: 'File Robot Import',
    blister: 'Manual Blister Production',
    update: 'Updates',
    support: 'Technical Support',
    patient30: 'Up to 50 Patients',
    patient31: 'Unlimited Patients',
    rx: 'Electronic Prescription Connection',
    inform: 'CCAA Posology Report',
    warning: 'Changes in Electronic Prescription',
    next: 'Next Dispensations',
    mach: 'Intersection of Posology with Electronic Prescription',
    sell: 'Sales Module',
    question: 'Still have questions?',
    question2:
      'To offer you the most suitable advice, indicate whether you represent a pharmacy, hospital, or laboratory. This way, we can provide you with personalized information according to the particularities of your sector.',
    contact: 'Contact Us',
    reserved: 'All rights reserved, made by',
    forgotpasswordintro:
      'Enter the email address associated with your account and we will send you a link to reset your password.',
    request: 'Send request',
    returnto: 'Return to login',
    choose: 'Do you need an CDS Robot?',
    choose1: 'Discover our CDS Robots',
    choose2:
      'We have a wide range of CDS robots to suit your specific needs. We offer models designed according to the volume of production, level of automation and format required, whether blister or bag. In addition, we have robots equipped with Oculus technology, which integrates verification and checking within the equipment itself.',
    siglas: 'CDS',
    robottitle: 'Explore Our Range of Robots',
    robotdescription:
      "If you're looking to optimize automation in CDS preparation, our robots are your ideal ally. We offer a wide range of CDS robots, from highly automated to those requiring less automation, to adapt to your specific needs. Thanks to our advanced technology and the universal FSP hoppers we provide, you can significantly reduce dependence on calibration. Additionally, our robots are equipped with the integrated OCULUS system, which provides detailed photographic evidence of each production, as well as comprehensive analysis of all doses.",
    catalog: 'Download Catalog',
    diff: 'Key Advantages of Our CDS Robots',
    diff1: 'Universal FSPs',
    diff1a:
      "With Universal FSPs, you can use the medication you need at the precise moment without complications and without calibrations. Our innovative technology ensures the use of any type of medication you desire, regardless of its shape or size. You'll enjoy greater freedom in your production.",
    diff2: 'High Turnover',
    diff2a:
      'To ensure constant availability of the most used molecules, we will carry out careful calibration. Our experts will conduct a thorough study to validate this selection, thus ensuring precise calibration and efficient automation. This approach allows us to achieve optimal automation.',
    diff3: 'TOTAL Traceability',
    diff3a:
      'With our robots, managing your medication is easier than ever. Simply scan the Sevem code to register the batch and expiration date on each dose produced. This quick and efficient process provides you with the ability to track your medication in detail, ensuring precise and safe control of it. ',
    robotnews: 'OCULUS System',
    robotnews2:
      'At Robotik, we have developed OCULUS technology, which is responsible for reviewing and analyzing all production through artificial vision. With OCULUS, forget about the time spent manually reviewing production; our technology does it for you. Additionally, it always leaves photographic evidence of everything produced with your Robotik robot. In summary, OCULUS provides you with total peace of mind.',
    discover: 'Discover the features of each CDS Robot',
    discover2: 'SELECT',
    tablerobots: 'The ideal CDS robot for all needs',
    footer:
      'The best single-dose CDS solution on the market. We are experts in implementing artificial intelligence in management processes.',
    corfirmform: 'Formulated received!',
    corfirmform2:
      'Our team will review the information provided and we will contact you shortly. Thank you for your interest!',
    tel: 'Telephone',
  },
  myprofile: {
    edit: 'Edit',
    user: 'User',
    name: 'Name and Surname',
    mail: 'Email',
    number: 'Phone Number',
    country: 'Country',
    state: 'State',
    city: 'City',
    address: 'Address',
    zip: 'Zip Code',
    company: 'Company',
    role: 'Role',
    save: 'Save Changes',
    delete: 'Delete User',
    verify: 'Email Verified',
    verifyinfo:
      'By deactivating this option, the user will automatically receive a verification email.',
    banned: 'Banned',
    apply: 'Deactivate account',
    new: 'Create User',
    permit: 'Allowed *.jpeg, *.jpg, *.png, *.gif',
    max: 'max size of',
    management: 'Management',
    search: 'Search...',
    list: 'User List',
    changecenter: 'Change Center',
    resetpass: 'Reset Password',
    confirmdelete: 'Do you want to delete the selected user?',
    dense: 'Dense',
    confirmreset: 'Email sent successfully',
    quick: 'Quick Edit Permissions',
  },
  pages: {
    pages: 'Pages',
    about: 'About Us',
    contact: 'Contact IDOOSE',
    inicio: 'Home',
    info: 'Information',
    community: 'Community',
    post: 'Posts',
    robot: 'CDS Robots',
  },
  about: {
    who: 'Who We Are?',
    about: 'About Us',
    contact: 'Contact IDOOSE',
    inicio: 'Home',
    info: 'Information',
    community: 'Community',
    post: 'Posts',
    whotitle: 'Who',
    are: 'We?',
    mision: 'Our mission is to improve the health and well-being of our patients',
    mision2: 'through the precise preparation of medications in unit doses, ensuring',
    mision3: 'safe and effective administration in a personalized manner.',
    whatis: 'What is IDOOSE?',
    text: "We are more than a medication management program; we seek to drive positive change in people's health. With the most advanced and accessible management program, we strive to provide tools that not only simplify medication administration but also promote community connection and overall well-being. Thanks to our AI, we offer the safest and most reliable service on the market in medication preparation. At IDOOSE, we offer a path to a healthier, simpler, and safer life.",
  },
  contact: {
    where: 'Where',
    find: 'are we?',
    contact: 'Do not hesitate to contact us. We will be happy to assist you.',
    name: 'Name',
    email: 'Email',
    subjet: 'Subject',
    message: 'Write your message here.',
    submit: 'Submit',
  },
  rulesimport: {
    list: 'Import Parameters',
    form: 'Sales Report',
    step1: 'Select File',
    step2: 'Conflict Review',
    step3: 'File Upload',
    importcorrect: 'Correct',
    sugest: 'Recommendation',
    new: 'New',
    noconflicts: 'File validated correctly, no conflicts detected.',
    sureimport: 'The database has been updated, click finish to complete the import.',
    importcomplete: 'Import has been completed successfully.',
  },
  calendar: {
    title: 'Calendar',
    event: 'New Event',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    agenda: 'Agenda',
    today: 'Today',
    new: 'Add Event',
    new2: 'Title',
    description: 'Description',
    allday: 'All Day',
    start: 'Start Date',
    end: 'End Date',
  },

  tablerobots: {
    tolvas: 'Number of Hoppers',
    univers: 'Universal FSP',
    width: 'Width (cm)',
    height: 'Height (cm)',
    depth: 'Depth (cm)',
  },
  yup: {
    yup_min: 'Too short!',
    yup_max: 'Too long!',
    yup_required: 'Field Required!',
    yup_email: 'Email must be a valid email address!',
    yup_phone: 'Phone number must contain only digits!',
  },
  cookie_consent: {
    acept: 'Accept',
    reject: 'Decline',
    message: 'Our website uses cookies to analyze traffic and improve our services.',
  },
  form_geometric: {
    1: 'Elongated',
    2: 'Blister',
    3: 'Ring',
    4: 'Arch',
    5: 'Bullet',
    6: 'Biconvex',
    7: 'Capsule',
    8: 'Cartridge',
    9: 'Cylindrical',
    10: 'Concave',
    11: 'Square',
    12: 'Elliptical',
    13: 'Spherical',
    14: 'Gas',
    15: 'Inhaler',
    16: 'Tear',
    17: 'Liquid',
    18: 'Apple',
    19: 'Oblong',
    20: 'Octagonal',
    21: 'Oval',
    22: 'Dust',
    23: 'Rectangular',
    24: 'Round',
    25: 'Rhombus',
    26: 'Pack',
    27: 'Triangular',
    28: 'Vial',
  },
  form_size: {
    0: 'Undefined',
    1: '2 mm (Micro)',
    2: '4 mm (Small)',
    3: '8 mm (Medium)',
    4: '12 mm (Large)',
    5: '18.0 mm (Very Large)',
    6: '23.3 mm (Extra Large)',
  },
  form_desc: {
    form_geometric: 'Geometric Shape',
    form_size: 'Size',
    form_slot: 'Slotted',
  },
  termsconditions: {
    title: 'IDOOSE Terms and Conditions of Use',
    subtitle: 'Welcome to IDOOSE',
    parraf1:
      'Thank you for choosing IDOOSE. Please read these terms and conditions carefully before using our software. By using IDOOSE, you accept these terms and conditions. If you do not agree with any of these terms, do not use our software.',
    subtitle2: '1. Scope of the Agreement and Acceptance',
    subtitle3: '1.1. Services Covered in the Agreement',
    parraf2:
      'This agreement applies to idoose.com and any other software, website, or service provided by IDOOSE ROBOTIK SL (collectively, the "services").',
    subtitle4: '1.2. Acceptance of This Agreement',
    parraf3:
      'By using the services of IDOOSE, you accept these terms and conditions without modifications. If you do not agree, do not use the services.',
    subtitle5: '1.3. Modification of the Agreement',
    parraf4:
      'IDOOSE ROBOTIK SL reserves the right to modify these terms and conditions at any time. We will notify you of any changes via email or through our website. Your continued use of the services after such changes constitutes your acceptance of the same.',
    subtitle6: '2. Use of Services and Restrictions',
    subtitle7: '2.1. Use of Software',
    parraf5:
      'IDOOSE is licensed, not sold. You must comply with all technical restrictions of the software and may not: • Circumvent the technical restrictions of the software • Decompile, disassemble, or reverse engineer the software, except where permitted by law. • Make more copies of the software than specified in this agreement. • Publish the software so others can copy it. • Rent, lease, or lend the software. • Transfer the software or this agreement to any third party.',
    subtitle8: '2.2. User Obligations',
    parraf6:
      'You agree to use the services responsibly and not to take any actions that may harm IDOOSE ROBOTIK SL or other users. This includes but is not limited to: • Not using the services for illegal or unauthorized activities. • Not interfering with the operation of the services or accessing the services through unauthorized methods. • Not collecting personal information from other users without their consent. • Not uploading or distributing viruses or any other type of malicious software.',
    subtitle9: '3. IDOOSE Account',
    subtitle10: '3.1. Account Creation',
    parraf7:
      'To access certain services, you will need an IDOOSE account. You are responsible for maintaining the confidentiality of your account information and password.',
    subtitle11: '3.2. Use of Account',
    parraf8:
      'You are responsible for all activities that occur under your account. You must notify IDOOSE ROBOTIK SL immediately of any unauthorized use of your account or any other security breach.',
    subtitle12: '4. Content',
    subtitle13: '4.1. Ownership of Content',
    parraf9:
      'The content you upload or transmit through the services remains your property. IDOOSE ROBOTIK SL does not claim ownership of your content.',
    subtitle14: '4.2. Responsibility for Content',
    parraf10:
      'You are solely responsible for the content you upload, store, or transmit through the services. You represent and warrant that you have all necessary rights to upload such content and that your content does not violate the rights of any third party.',
    subtitle15: '4.3. Review and Deletion of Content',
    parraf11:
      'IDOOSE ROBOTIK SL reserves the right to review content uploaded to its servers and remove any content that, at its discretion, violates these terms and conditions or applicable law.',
    subtitle16: '5. Termination of Services',
    subtitle17: '5.1. By the User',
    parraf12:
      'You may cancel your IDOOSE account at any time through your account management pages. The cancellation will take effect at the end of your billing period.',
    subtitle18: '5.2. By IDOOSE ROBOTIK SL',
    parraf13:
      'IDOOSE ROBOTIK SL reserves the right to cancel your account or access to the services at any time, with or without cause, and without prior notice.',
    subtitle19: '6. Applicable Law',
    parraf14:
      'This agreement is governed by the laws of Spain, without giving effect to any choice of law principles. Any dispute arising in connection with this agreement will be subject to the exclusive jurisdiction of the courts of Spain.',
    subtitle20: '7. Contact Information',
    parraf15: 'For any questions about this agreement, you can contact us at info@idoose.com.',
  },
  privacy: {
    title: 'Privacy Policy',
    subtitle: 'IDOOSE ROBOTIK SL',
    parraf1:
      'At IDOOSE ROBOTIK SL, located at Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564, and CIF B56740137, we are committed to protecting the privacy and security of our users. This Privacy Policy describes how we collect, use, and protect your personal information when you use our software.',
    subtitle2: '1. Information We Collect',
    parraf2:
      'We may collect the following types of information: Personal identification information: Name, address, phone number, email address, and any other information you voluntarily provide. Software usage information: Data about how you interact with our software, including the features you use and the frequency of use. Technical information: Technical data about your device, such as IP address, browser type, operating system, and language settings.',
    subtitle3: '2. Use of Information',
    parraf3:
      'We use the collected information to: Provide, operate, and maintain our software. Improve, personalize, and expand our software. Understand and analyze how you use our software. Develop new features, products, and services. Communicate with you, either directly or through one of our partners, including for customer service, providing updates and other information related to the software, and for marketing and promotional purposes. Process your transactions and manage your account. Send periodic emails.',
    subtitle4: '3. Protection of Your Information',
    parraf4:
      'We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. However, please remember that no method of transmission over the Internet or method of electronic storage is 100% secure.',
    subtitle5: '4. Sharing Your Information',
    parraf5:
      'We do not sell, trade, or transfer your personal identification information to third parties. This does not include trusted third parties who assist us in operating our software, conducting our business, or serving you, as long as those parties agree to keep this information confidential. We may also disclose your information when we believe that disclosure is appropriate to comply with the law, enforce our site policies, or protect our rights, property, or safety.',
    subtitle6: '5. Your Rights',
    parraf6:
      'You have the right to access, rectify, cancel, and object to the processing of your personal data. To exercise these rights, you can contact us via email at info@idoose.com or send a letter to our address: Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564.',
    subtitle7: '6. Changes to This Privacy Policy',
    parraf7:
      'We reserve the right to update this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. It is recommended to review this Privacy Policy periodically to stay informed of any changes.',
    subtitle8: '7. Contact',
    parraf8:
      'If you have any questions about this Privacy Policy, you can contact us: IDOOSE ROBOTIK SL Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564 Email: info@idoose.com',
  },
  payments: {
    title: 'Summary',
    totalFacturado: 'Total Billed',
    taxes: 'Plus applicable taxes',
    compra: 'Buy',
    securitycard: 'Secure credit card payment',
    encrypted: 'This is a secure 128-bit SSL encrypted payment',
    subscription: 'Subscription',
    subrealizada: 'Subscription plan successful!',
    manage: 'Manage your billing information',
    mes: '/ Month',
  },
};

export default en;

const de = {
  demo: {
    title: 'German',
    introduction:
      'Lorem Ipsum ist einfach Dummy-Text der Druck- und Satzindustrie. Lorem Ipsum ist seit dem 16. Jahrhundert der Standard-Dummy-Text der Branche, als ein unbekannter Drucker eine Galeere vom Typ nahm und sie zu einem Musterbuch verschlüsselte. Es hat nicht nur fünf Jahrhunderte überlebt, sondern auch den Sprung in den elektronischen Satz, der im Wesentlichen unverändert geblieben ist. Es wurde in den 1960er Jahren mit der Veröffentlichung von Letraset-Blättern mit Lorem Ipsum-Passagen und in jüngerer Zeit mit Desktop-Publishing-Software wie Aldus PageMaker einschließlich Versionen von Lorem Ipsum populär gemacht.',
  },
  resetpass: {
    mesage: 'Passwort vergessen?',
    info: 'Geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein, und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.',
    reset: 'Zurücksetzen',
    back: 'Zurück',
    sent: 'Anfrage erfolgreich gesendet',
    confirmation: 'Wir haben eine Bestätigungs-E-Mail an gesendet',
    check: 'Überprüfen Sie Ihre E-Mails',
    check1:
      'Wir haben einen Bestätigungscode mit 6 Ziffern per E-Mail gesendet. Bitte geben Sie den Code unten ein, um Ihre E-Mail-Adresse zu bestätigen.',
    verify: 'Überprüfen',
    resend: 'Code erneut senden',
    donthave: 'Keinen Code erhalten? ',
    return: 'Zurück zur Startseite',
    success: 'Anfrage erfolgreich gesendet!',
    success2:
      'Wir haben eine Bestätigungs-E-Mail mit einem 6-stelligen Code an Ihre E-Mail-Adresse gesendet.',
    success3: 'Bitte geben Sie den Code unten ein, um Ihre E-Mail-Adresse zu bestätigen.',
    newpass: 'Passwort aktualisieren',
    confirmpass: 'Neues Passwort bestätigen',
    pass: 'Passwort',
    checkpegatina : 'Überprüfen Sie den physischen Aufkleber',
    checkpegatina2 : 'Um die Sicherheit Ihrer sensiblen Daten zu gewährleisten, ist die 6-stellige Bestätigungs-PIN auf dem physischen Aufkleber aufgedruckt. Geben Sie diese PIN in das Feld unten ein, um den Zugriff auf vertrauliche Informationen sicherzustellen. Wenn Sie die PIN nicht haben, melden Sie sich an und eine Eingabe ist nicht erforderlich.',
    errorpin: 'Falsche PIN'
  },
  newparameter: {
    back: 'Zurück',
    next: 'Weiter',
    scope: 'Parameterbereich',
    type: 'Parameterart',
    config: 'Konfiguration',
    posology: 'Gemäß Posologie verkaufen',
    swap: 'Verschreibung ersetzen',
    notdispense: 'Nicht abgeben',
    priority: 'Favorisierte Farmatic-Highlights priorisieren',
    add: 'Fertigstellen',
    priorityconfirm: 'Ab jetzt werden Ihre bevorzugten Medikamente priorisiert.',
    posologyconfirm: 'Ab jetzt wird nur noch gemäß Posologie verkauft.',
    selectsust: 'Zu ersetzende Verschreibung auswählen',
    selectsust2: 'Zu verschreibende Verschreibung auswählen.',
    notdispence2: 'Wählen Sie die Verschreibung aus, die nicht abgegeben werden soll.',
    number: 'Nummer',
    scopetable: 'Betroffen',
    name: 'Name',
    typeparameter: 'Parameterart',
    code: 'Nationale Codes',
    patient: 'Patient',
    center: 'Zentrum',
    pharmacy: 'Apotheke',
    swaptype: 'Verschreibung ersetzen',
    prioritytype: 'Favorisierte Highlights',
    posologytype: 'Gemäß Posologie',
    notdispencetype: 'Nicht abgeben',
    no_financied: 'Nur finanzierbar',
    no_narcotic: 'Keine Betäubungsmittel verkaufen',
    maxstock: 'Maximaler Lagerbestand',
    max_box_dps: 'Maximale Einheiten',
    no_regex: 'Nicht abgeben nach "Wort"',
    order_lab: 'Laborpriorisierung',
    check_stock: 'Mit Lagerbestand verkaufen',
    force_posology: 'Gemäß CN der Posologie verkaufen',
    laboratory: 'Labor-Liste',
    replace_max_stock: 'Ersetzung durch maximalen Lagerbestand',
    cst_change: 'Letzte Nachfüllung im Hopper',
  },
  table: {
    column: 'Spalten',
    find: 'Finden Sie Ihre Spalte.',
    columntitle: 'Spaltentitel',
    hyde: 'Alle abwählen',
    mark: 'Alle auswählen',
    filters: 'Filter',
    density: 'Anzeigeformat',
    density1: 'Kompakt',
    density2: 'Normal',
    density3: 'Groß',
    export: 'Exportieren',
    operators: 'Operatoren',
    contains: 'enthält',
    equals: 'ist gleich',
    start: 'beginnt mit',
    end: 'endet mit',
    empty: 'ist leer',
    noempty: 'ist nicht leer',
    value: 'Wert',
    filtervalue: 'Wert filtern',
    download: 'Als CSV herunterladen',
    print: 'Drucken',
    download2: 'Als Excel herunterladen',
    columnMenuLabel: 'Menü',
    columnMenuShowColumns: 'Spalten anzeigen',
    columnMenuManageColumns: 'Spalten verwalten',
    columnMenuFilter: 'Filtern',
    columnMenuHideColumn: 'Spalte ausblenden',
    columnMenuUnsort: 'Sortierung aufheben',
    columnMenuSortAsc: 'Aufsteigend sortieren',
    columnMenuSortDesc: 'Absteigend sortieren',
  },
  menu: {
    general: 'MENÜ',
    order: 'Bestellverwaltung',
    'historic Orders': 'Historische Bestellungen',
    catalog: 'Medikamente',
    patient: 'Patienten',
    posology: 'Posologie',
    newpro: 'Produktion',
    Laboratorys: 'Laboratorien',
    Config: 'Konfiguration',
    management: 'VERWALTUNG',
    user: 'Benutzer',
    profile: 'Mein Profil',
    list: 'Benutzerverwaltung',
    myinstallation: 'Meine Installation',
    subscription: 'Meine Apotheke',
    invoice: 'Rechnung',
    centers: 'Zentren',
    centersmanage: 'Zentrenverwaltung',
    virtual: 'KI Mery',
    available: 'Bestellungen',
    parameters: 'Parameter',
    listrx: 'Rezepte pro Patient',
    reports: 'Elektronisches Rezept',
    traceability: 'Rückverfolgbarkeit',
    alerts: 'Warnungen',
    history: 'Verlauf',
    medpatient: 'Medikation pro Patient',
    merge: 'Posologieübereinstimmung',
    take: 'Medikamentenausgabe',
    import: 'Import',
    inicio: 'Startseite',
    upload: 'Datei hochladen',
    parametersimport: 'Importparameter',
    task: 'Geplante Aufgaben',
    electronic: 'Gesundheitskarten',
    availableia: 'Verfügbar',
    match: 'Posologie / Rezeptabgleich',
    sellparameters: 'Verkaufsparameter',
    form0: 'Berichte',
    form1: 'Produktionsbericht',
    form2: 'Medikation pro Patient',
    form3: 'Rückverfolgbarkeit',
    chat: 'Chat',
    calendar: 'Kalender',
    support: 'Technischer Support',
    assist: 'Unterstützung',
  },
  dropdown: {
    Home: 'Startseite',
    Profile: 'Profil',
    Settings: 'Einstellungen',
    logout: 'Abmelden',
  },
  alert: {
    success: 'Zugriff gewährt',
    newuser: 'Neuer Benutzer',
    verified: 'Verifiziert',
    yes: 'Ja',
    no: 'Nein',
    edit: 'Bearbeiten',
    deleteuser: 'Benutzer löschen',
    confirmation: 'Sind Sie sicher, dass Sie diesen/diese Benutzer löschen möchten?',
  },
  myuser: {
    general: 'Allgemein',
    pass: 'Passwort ändern',
    allow: 'Erlaubt',
    sice: 'Maximale Größe von',
    save: 'Änderungen speichern',
    about: 'Kommentar',
    old: 'Aktuelles Passwort',
    new: 'Neues Passwort',
    mustbe: 'Das Passwort muss mindestens 6 oder mehr Zeichen lang sein.',
    confirm: 'Neues Passwort bestätigen',
    banned: 'Deaktiviert',
    applyban: 'Deaktivierung des Kontos anwenden.',
    savechanges: 'Änderungen speichern',
    upload: 'Datei hochladen',
    photo: 'Foto hochladen',
    update: 'Foto aktualisieren',
  },
  register: {
    mesage: 'Schnell, einfach und intuitiv.',
    intro: 'Erstellen Sie Ihr Konto und beginnen Sie mit unseren Lösungen.',
    intro2: 'Es ist ganz einfach!',
    name: 'Vorname und Nachname',
    lastname: 'Nachname',
    namepharma: 'Name der Einrichtung',
    create: 'Registrieren',
    already: 'Sie haben bereits ein Konto? ',
    firstnamerec: 'Der Vorname ist erforderlich',
    lastnamerec: 'Der Nachname ist erforderlich',
    emailrec: 'Die E-Mail-Adresse ist erforderlich',
    passrec: 'Das Passwort ist erforderlich',
  },
  centers: {
    list: 'Zentrenliste',
    title: 'Zentrumsdetails',
    nif: 'NIF',
    date: 'Registrierungsdatum',
    production: 'Produktionsmodus',
    machinepro: 'Ausgewähltes Gerät',
    print1: 'Nicht verpackbare Elemente drucken.',
    print2: 'Nach Zeitpunkten drucken.',
    print3: 'Bei Bedarf drucken.',
    code: 'Nach Medikamentencode',
    code2: 'Nach Verpackungsfamilie',
    code3: 'Nach biologischen Familien',
    change: 'Möchten Sie das Zentrum wechseln?',
    changeinstallation: 'Möchten Sie die Installation wechseln?',
    sure: 'Sind Sie sicher, dass Sie das Zentrum wechseln möchten?',
    acept: 'Akzeptieren',
    addnew: 'Neue Standardrichtlinie',
    namenewpred: 'Name der Richtlinie',
    syncdate: 'Enddatum des elektronischen Rezepts synchronisieren',
  },
  listmedicine: {
    medication: 'Medikation',
    search: 'Suche',
    filter: 'Medikamentenfilter',
    all: 'Alle',
    tipemedication: 'Medikamententyp',
    familya: 'Verpackungsfamilie',
    familyb: 'Biologische Familie',
    inactive: 'Inaktiv',
    active: 'Aktiv',
    ficha: 'Medikamentenblatt',
    brand: 'Marke',
    generic: 'Generisch',
    needrecipe: 'Rezept erforderlich',
    code: 'Code',
    name: 'Name',
    codefamily: 'Biologische Familiennummer',
    namefamily: 'Biologischer Familienname',
    units: 'Einheiten pro Schachtel',
    atc: 'ATC-Code',
    packable: 'Verpackbar',
    psicotropic: 'Psychotrop',
    narcotic: 'Narkotisch',
    warning: 'Warnungen',
    replaceable: 'Austauschbar',
    clinic: 'Klinische Verpackung',
    use: 'Krankenhausgebrauch',
    comer: 'Vermarktet',
    tld: 'TLD',
    para: 'Parapharmazie',
    control: 'Besondere ärztliche Kontrolle',
    drive: 'Fahreffekte',
    orphan: 'Waisen',
    recipe: 'Rezept',
    bio: 'Biosimilar',
    save: 'Speichern',
    cancel: 'Abbrechen',
  },
  llistpatient: {
    title: 'Patientenliste',
    search: 'Suche',
    active: 'Aktiv',
    ficha: 'Patientendatenblatt',
    name: 'Name',
    dni: 'DNI/NIE',
    born: 'Geburtsdatum',
    ss: 'Sozialversicherungsnummer',
    phone: 'Telefon',
    sex: 'Geschlecht',
    female: 'Weiblich',
    male: 'Männlich',
    bed: 'Bett',
    region: 'Region',
    room: 'Zimmer',
    address: 'Adresse',
    country: 'Land',
    city: 'Stadt',
    cp: 'PLZ',
    mail: 'E-Mail',
    save: 'Speichern',
    cancel: 'Abbrechen',
    sync: 'Synchronisierung',
    journal: 'Medikationsverlauf',
    electronic: 'Elektronisches Rezept',
    group: 'Gruppe',
    doctorname: 'Name des Arztes'
  },
  production: {
    status: 'Status',
    title: 'Neue Produktion',
    code: 'Code',
    datecreate: 'Erstellungsdatum',
    dateproduction: 'Produktionsdatum',
    lastprox: 'Nächste/Letzte Abgabe',
    initialdose: 'Anfangs/Letzte Abgabe',
    dateend: 'Enddatum',
    cancel: 'Abbrechen',
    pendent: 'Ausstehend',
    working: 'In Bearbeitung',
    produced: 'Produziert',
    deleted: 'Gelöscht',
    repackaging: 'Umpackproduktion',
    pouch: 'Anzahl der Dosen',
    dose: 'Einheiten pro Dosis',
    printlabel: 'Etiketten drucken',
    labelsearch: 'Nach Namen suchen',
    cretit_none: 'Unzureichende Guthaben',
    cretit_none1: 'Derzeit hast du nicht genügend Guthaben, um diese Produktion durchzuführen.',
    cretit_none2: 'Bitte lade dein Konto auf, um fortzufahren.',
    cretit_none3: 'Aktuelles Guthaben',
    cretit_none4: 'Benötigtes Guthaben',
    cretit_none5: 'Guthaben'
  },
  dashboard: {
    title: 'Willkommen zurück 👋',
    titlecomment:
      'Mit unserer fortschrittlichen KI bieten wir den sichersten und zuverlässigsten Service auf dem Markt.',
    discover: 'Mehr entdecken',
    patients: 'Patienten',
    medication: 'Aktive Medikation',
    production: 'Produktionen',
    caducity: 'Medikamente kurz vor Ablauf',
    data: 'Daten der letzten Produktion: ',
    total: 'Insgesamt abgegeben:',
    packable: 'Verpackbar',
    unpackable: 'Nicht verpackbar',
    dispensed: 'Abgegeben',
    activpatient: 'Aktive Patienten',
    activposology: 'Aktive Behandlungen',
    totalcount: 'Abgegebene Einheiten diesen Monat',
    type: 'Merkmale',
    if_needed: 'Wenn benötigt',
    iaquality: 'KI-Qualität',
    merge: 'Korrekt',
    rxx: 'Kein Rezept',
    posology: 'Keine Posologie',
    reviwer: 'Rezension',
    dep: 'Einzahlung',
    ranking: 'TOP-Änderungen',
  },
  posology: {
    force_print: 'Nur drucken',
    no_sync: 'Geschützt',
    title: 'Dosierung',
    search: 'Suche',
    active: 'Aktiv',
    inactive: 'Inaktive Medikation',
    save: 'Speichern',
    cancel: 'Abbrechen',
    packable: 'Verpackbar',
    unpackable: 'Nicht verpackbar',
    start: 'Startdatum',
    end: 'Enddatum',
    dosis: 'Dosis',
    period: 'Tage pro Periode',
    cronic: 'Chronisch',
    days: 'Tage',
    hour: 'Stunden pro Periode',
    starthour: 'Startzeit',
    months: 'Monate',
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
    week: 'Wöchentlich',
    ciclica: 'Zyklisch (Tage)',
    ciclicb: 'Zyklisch (Stunden)',
    especilavar: 'Variable',
    month: 'Monatlich',
    aparte: 'Getrennte Verpackung',
    optional: 'Bei Bedarf',
    irreplaceable: 'Unersetzlich',
    moment: 'Moment',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    add: 'Dosierung hinzufügen',
    name: 'Name der Dosierung',
    selecttitle: 'Medikation auswählen',
    select: 'Auswählen',
    addtoma: 'Hinzufügen',
    cannot: 'Sie können keine leere Dosierung speichern.',
    confirmation: 'Bestätigung',
    confirmationtext: 'Sind Sie sicher, dass Sie diese Behandlung löschen möchten?',
    confirmationdelete: 'Löschen',
    update: 'Änderungen erfolgreich gespeichert.',
    error: 'Fehler beim Löschen der Dosierung.',
    erroradd: 'Fehler beim Hinzufügen der Dosierung.',
    addsuccess: 'Erfolgreich hinzugefügt.',
    deletesuccess: 'Erfolgreich gelöscht.',
    errordate: 'Das Startdatum kann nicht in der Vergangenheit liegen.',
    exist: 'Dosierung existiert bereits.',
    periodless: 'Die Periode kann nicht weniger als einen Tag betragen.',
    daysless: 'Die Anzahl der Tage kann nicht weniger als 1 sein.',
    hourless: 'Die Anzahl der Stunden kann nicht weniger als 1 sein.',
    hourerror: 'Die Periode kann nicht mehr als 24 Stunden betragen.',
    dayerror: 'Der Tag kann nicht größer als 31 oder kleiner als 0 sein.',
    delete: 'Dosierung löschen',
    note: 'Notizen',
    interaction: 'Arzneimittelinteraktionen',
    deletedose: 'Möchten Sie die ausgewählte Behandlung wirklich löschen?',
  },
  login: {
    mesage: 'Hallo, willkommen',
    introduction:
      'Lorem Ipsum ist einfach Dummy-Text der Druck- und Satzindustrie. Lorem Ipsum ist seit dem 16. Jahrhundert der Standard-Dummy-Text der Branche, als ein unbekannter Drucker eine Galeere vom Typ nahm und sie zu einem Musterbuch verschlüsselte. Es hat nicht nur fünf Jahrhunderte überlebt, sondern auch den Sprung in den elektronischen Satz, der im Wesentlichen unverändert geblieben ist. Es wurde in den 1960er Jahren mit der Veröffentlichung von Letraset-Blättern mit Lorem Ipsum-Passagen und in jüngerer Zeit mit Desktop-Publishing-Software wie Aldus PageMaker einschließlich Versionen von Lorem Ipsum populär gemacht.',
    signin: 'Bei IDOOSE anmelden',
    details: 'Geben Sie Ihre Daten unten ein.',
    inicio: 'Anmelden',
    agree: 'Indem ich mich anmelde, akzeptiere ich die ',
    terms: 'Servicebedingungen',
    privacy: 'Datenschutzbestimmungen',
    and: ' und ',
    account: 'Neuer Benutzer? ',
    started: 'Ein Konto erstellen',
    email: 'E-Mail',
    password: 'Passwort',
    rememberme: 'Angemeldet bleiben',
    forgotpassword: 'Passwort vergessen?',
    requiremail: 'Die E-Mail-Adresse ist erforderlich',
    requirepass: 'Das Passwort ist erforderlich',
    requirement: 'Bei der Registrierung akzeptiere ich die ',
    requirement2: ' Nutzungsbedingungen',
    requirement3: ' und die ',
    requirement4: 'Datenschutzrichtlinie',
  },

  virtual: {
    list: 'Rezeptliste',
    new: 'Neues Rezept',
    active: 'Aktiv',
    expired: 'Abgelaufen',
    expiredate: 'Verfallsdatum',
    renewdate: 'Erneuerungsdatum',
    raw: 'Band / Chip / TSI / Rezeptnummer',
    order: 'Strichcode (nur CAT)',
    review: 'Überprüfung',
    assignment: 'Codezuweisung',
    sell: 'Verkaufen',
    create: 'Bestellung erstellen',
    title: 'Geplante Aufgaben',
    number: 'Bestellnummer',
    status: 'Status',
    date: 'Startdatum',
    patients: 'Anzahl der Patienten',
    titleparameters: 'Verkaufsparameter',
    confirm: 'Möchten Sie das ausgewählte Rezept wirklich löschen?',
    deletesinc: 'Möchten Sie die ausgewählte Synchronisierung wirklich löschen?',
    productionform: 'Produktionsbericht',
    prepareinform: 'Bericht vorbereiten',
    export: 'In Excel exportieren',
  },
  trazability: {
    ema: 'Europäische Arzneimittel-Agentur (EMA)',
    text: 'Die Europäische Arzneimittel-Agentur (EMA) ist eine dezentrale Agentur der Europäischen Union (EU), die für die wissenschaftliche Bewertung, Überwachung und Überwachung der Arzneimittelsicherheit in der EU verantwortlich ist. Die EMA ist eine Netzwerkorganisation, deren Aktivitäten Tausende von Experten aus ganz Europa einbeziehen. Diese Experten führen die Arbeit der wissenschaftlichen Ausschüsse der EMA durch.',
    discover: 'Entdecken Sie die EMA',
    detections: 'Erkannte Warnungen',
    eligible: 'Behandelbare Warnungen',
    lastupdate: 'Letztes Update',
    totals: 'Insgesamt überprüfte Medikation',
  },
  reportpatientproductionlist: {
    lng_title: 'BERICHT ÜBER PRODUZIERTE PATIENTEN',
    lng_titlelistcenter: 'LISTE DER PATIENTEN',
    lng_phone: 'Telefon',
    lng_mail: 'E-Mail',
    lng_num_production: 'Produktionsnummer',
    lng_center: 'Zentrum',
    lng_date_int: 'Startdatum',
    lng_date_end: 'Enddatum',
    lng_code: 'Code',
    lng_name: 'Name',
    lng_send: 'Senden',
    lng_reception: 'Empfang',
    lng_date: 'Datum',
    lng_no_medication: 'Ohne Vermittlung',
    lng_reviwer: 'Überprüft von:',
    lng_produced: 'Produziert von:',
  },
  reportmedicineproductionlist: {
    lng_title: 'BERICHT ÜBER GRUPPIERTE MEDIKATION',
    lng_title2: 'NACHFÜLLBERICHT',
    lng_title3: 'BERICHT ÜBER MEDIKATION PRO PATIENT',
    lng_phone: 'Telefon',
    lng_mail: 'E-Mail',
    lng_num_production: 'Prod.-Nr.',
    lng_center: 'Zentrum',
    lng_date_int: 'Startdatum',
    lng_date_end: 'Enddatum',
    lng_code: 'Code',
    lng_name: 'Name',
    lng_qty: 'Einheiten',
    lng_yes: 'Ja',
    lng_no: 'Nein',
    lng_packable: 'Verpackbar',
    lng_date: 'Datum',
    lng_dev: 'Trichter',
    lng_lot: 'Charge',
    lng_exp: 'Ablaufdatum',
    lng_qty2: 'Rest',
  },
  reportposologyproductionlist: {
    lng_title_not_packing: 'NICHT VERPACKBARE MEDIKATION',
    lng_title_packing: 'VERPACKBARE MEDIKATION',
    lng_mo2: 'Montag',
    lng_tu2: 'Dienstag',
    lng_we2: 'Mittwoch',
    lng_th2: 'Donnerstag',
    lng_fr2: 'Freitag',
    lng_sa2: 'Samstag',
    lng_su2: 'Sonntag',

    lng_title: 'PHARMAKOLOGISCHER PLAN',
    lng_phone: 'Telefon',
    lng_mail: 'E-Mail',
    lng_date: 'Datum',
    lng_num_patient: 'Patient Nr.',
    lng_name_patient: 'Name',
    lng_dni: 'Personalausweis',
    lng_ss: 'Sozialversicherungsnummer',
    lng_code_medicine: 'Code',
    lng_name_medicine: 'Medikamentenname',
    lng_posology: 'Dosierung',
    lng_date_int: 'Startdatum',
    lng_date_end: 'Enddatum',
    lng_cronic: 'Chronisch',
    lng_mo: 'Mo',
    lng_tu: 'Di',
    lng_we: 'Mi',
    lng_th: 'Do',
    lng_fr: 'Fr',
    lng_sa: 'Sa',
    lng_su: 'So',
    lng_day: 'Tage',
    lng_month: 'Monate',
    lng_ja: 'Jan',
    lng_fe: 'Feb',
    lng_ma: 'Mär',
    lng_ap: 'Apr',
    lng_my: 'Mai',
    lng_jn: 'Jun',
    lng_jl: 'Jul',
    lng_au: 'Aug',
    lng_se: 'Sep',
    lng_oc: 'Okt',
    lng_no: 'Nov',
    lng_de: 'Dez',
    lng_month_all: 'Alle Monate',
    lng_hour: 'Alle',
    lng_hour_x: 'Stunden ab',
  },

  reportchangeslist: {
    lng_title_not_packing: 'NICHT VERPACKBARE MEDIKAMENTE',
    lng_title_packing: 'VERPACKBARE MEDIKAMENTE',
    lng_mo2: 'MONTAG',
    lng_tu2: 'DIENSTAG',
    lng_we2: 'MITTWOCH',
    lng_th2: 'DONNERSTAG',
    lng_fr2: 'FREITAG',
    lng_sa2: 'SAMSTAG',
    lng_su2: 'SONNTAG',

    lng_title: 'ÄNDERUNGSBERICHT',
    lng_phone: 'Telefon',
    lng_mail: 'E-Mail',
    lng_date: 'Datum',
    lng_num_patient: 'Patientennummer',
    lng_name_patient: 'Name',
    lng_dni: 'Personalausweis',
    lng_ss: 'Sozialversicherungsnummer',
    lng_code_medicine: 'Code',
    lng_name_medicine: 'Medikamentenname',
    lng_posology: 'Dosierung',
    lng_date_int: 'Startdatum',
    lng_date_end: 'Enddatum',
    lng_cronic: 'Chronisch',
    lng_mo: 'M',
    lng_tu: 'D',
    lng_we: 'M',
    lng_th: 'D',
    lng_fr: 'F',
    lng_sa: 'S',
    lng_su: 'S',
    lng_day: 'Tage',
    lng_month: 'Monate',
    lng_ja: 'JAN',
    lng_fe: 'FEB',
    lng_ma: 'MÄR',
    lng_ap: 'APR',
    lng_my: 'MAI',
    lng_jn: 'JUN',
    lng_jl: 'JUL',
    lng_au: 'AUG',
    lng_se: 'SEP',
    lng_oc: 'OKT',
    lng_no: 'NOV',
    lng_de: 'DEZ',
    lng_month_all: 'Alle Monate',
    lng_hour: 'Alle',
    lng_hour_x: 'Stunden ab',
  },

  sync: {
    new: 'Synchronisation hinzufügen',
  },
  reportpatientjournallist: {
    lng_title: 'PATIENTENZAHLUNGSBERICHT',
    lng_phone: 'Telefon',
    lng_mail: 'E-Mail',
    lng_date: 'Datum',
    lng_num_patient: 'Patientennummer',
    lng_name_patient: 'Name',
    lng_dni: 'Personalausweis',
    lng_ss: 'Sozialversicherungsnummer',
    lng_code_medicine: 'Code',
    lng_name_medicine: 'Medikamentenname',
    lng_prod: 'Produziert',
    lng_seller: 'Verkauft',
    lng_aport: 'Beigetragen',
    lng_total: 'Gesamt',
  },
  reportpatientconsent: {
    lng_anx_1: 'Ich möchte am Programm zur personalisierten Dosiersysteme der Apotheke teilnehmen.',
    lng_anx_2:
      'Ich verstehe, dass dieses Programm die Verpackung der Medikamente in einem personalisierten Dosiersystem beinhaltet.',
    lng_anx_3:
      'Ich nehme freiwillig an diesem Programm teil und kann jederzeit entscheiden, daran nicht mehr teilzunehmen, indem ich einfach diese Einwilligung widerrufe, indem ich den entsprechenden Abschnitt ausfülle.',
    lng_anx_4:
      'Die Teilnahme an diesem Projekt bedeutet, dass, wenn sie von handelsüblichen Behältnissen ausgeht, die Medikamente in ihren Originalverpackungen in der Apotheke abgegeben werden, wo der Apotheker für ihre ordnungsgemäße Aufbewahrung und Lagerung verantwortlich ist.',
    lng_anx_5: 'Ich verpflichte mich dazu:',
    lng_anx_51: 'Jede Änderung meiner Medikation dem Apotheker mitzuteilen.',
    lng_anx_52: 'Die Aufbewahrungs- und Sicherheitsbedingungen des Blisterpacks einzuhalten.',
    lng_anx_53:
      'Die leeren Geräte aus den vergangenen Wochen zur Überprüfung der Einhaltung und möglicher Anwendungsfehler abzugeben.',
    lng_anx_6: 'Der Apotheker verpflichtet sich dazu:',
    lng_anx_61:
      'Mir etwaige Probleme im Zusammenhang mit dem Medikament mitzuteilen, die er feststellen könnte.',
    lng_anx_62:
      'Mir die notwendigen Informationen für die richtige Verwendung der personalisierten Dosiergeräte und der Medikamente selbst zur Verfügung zu stellen.',
    lng_anx_7:
      'Ich ermächtige den Apotheker zur Verarbeitung meiner personenbezogenen Daten, die für die Teilnahme am Programm erforderlich sind*.',
    lng_consent: 'INFORMIERTE EINWILLIGUNG',
    lng_date: 'Datum',
    lng_dni: 'DNI',
    lng_don_d: 'Herr/Frau',
    lng_lopd_1:
      '*Die für die Teilnahme am Programm für personalisierte Dosiersysteme erforderlichen personenbezogenen Daten werden in einer Datei namens Blisterzentren gespeichert, die ordnungsgemäß bei der spanischen Datenschutzbehörde registriert ist und deren Verantwortlicher die Apotheke ist.',
    lng_lopd_2:
      'Sie verfügen über alle erforderlichen Sicherheitsmaßnahmen gemäß dem Datenschutzgesetz, werden ausschließlich zur Verwaltung der personalisierten Dosiergeräte des Patienten verwendet und können an medizinisches Fachpersonal, Angehörige und/oder Betreuer des Patienten weitergegeben oder mit ihnen kommuniziert werden, um die Behandlung zu überprüfen und/oder die Einhaltung zu verbessern. Sie werden in keinem Fall zu Werbe- oder kommerziellen Zwecken verwendet und unterliegen der ärztlichen Schweigepflicht des Apothekers. Personen, deren Daten in diesen Dateien erfasst sind, haben das Recht, darauf zuzugreifen, sie zu berichtigen oder zu löschen, wenn sie dies für angemessen halten, und können sich dazu an dieselbe Apotheke wenden, unter Beachtung der gesetzlichen Bestimmungen.',
    lng_mail: 'E-Mail',
    lng_name_d: 'Patient/gesetzlicher Vertreter',
    lng_name_patient: 'Name',
    lng_name_ph_d: 'Apotheker',
    lng_name_ph_sin_d: 'Unterschrift des Apothekers',
    lng_name_sin_d: 'Unterschrift des Patienten/gesetzlichen Vertreters',
    lng_num_patient: 'Patientennummer',
    lng_phone: 'Telefon',
    lng_ss: 'Sozialversicherungsnummer',
    lng_title: 'INFORMIERTE EINWILLIGUNG DES PATIENTEN',
  },
  Bob: {
    ordernumber: 'Bestellnummer',
    status: 'Status',
    process: 'Prozess',
    Startorder: 'Auftragsstart',
    createdate: 'Erstellungsdatum',
    selling: 'Verkauf',
    reviewing: 'Überprüfung',
    wait: 'Warten',
    sevem: 'SEVEM',
    patient: 'Patient',
    drug: 'Medikament',
    recipe: 'Elektronisches Rezept',
    initialdate: 'Behandlungsbeginn',
    expiredate: 'Ablaufdatum',
    posology: 'Dosierung',
    scan: 'Code scannen',
    dep: 'Einzahlung',
    without1: 'Keine Dosierung',
    without2: 'Kein Rezept',
    reviwer: 'Rezension',
    correct: 'Korrekt',
    lot: 'Charge',
    neworder: 'Neue Bestellung',
    deleteorder: 'Möchten Sie die ausgewählte Arbeitsbestellung wirklich löschen?',
    numberorder: 'Bestellnummer:',
    history: 'Rückverfolgungshistorie',
    scancodes: 'Codezuweisung',
    forceall: 'Alle auf CN erzwingen',
    last: 'Letztes Update',
    deleteparameter: 'Möchten Sie den ausgewählten Parameter wirklich löschen?',
    sellformtitle: 'VERKAUFBERICHT',
  },
  official: {
    error: 'Dokument nicht verfügbar.',
  },
  display: {
    prospectus: 'Prospectus',
    technical: 'Technisches Datenblatt',
    unpackable: 'Nicht verpackbar',
  },
  notfound: {
    sorry: 'Seite nicht gefunden.',
    sorry2: 'Entschuldigung, wir konnten die von Ihnen gesuchte Seite nicht finden.',
    home: 'Startseite',
  },
  editproduction: {
    edit: 'Produktion bearbeiten',
    confirm: 'Möchten Sie die Produktion wirklich bearbeiten?',
    home: 'Startseite',
    predose: 'Standard-Dosierungen',
    addnewpre: 'Neue Standard-Dosierung hinzufügen',
    nametome: 'Name der Dosierung',
    hourtome: 'Uhrzeit der Dosierung',
    confirmationtext: 'Sind Sie sicher, dass Sie diese Standard-Dosierung löschen möchten?',
  },

  blisterpro: {
    process: 'FORTSCHRITT',
    sorry2: 'Entschuldigung, wir konnten die von Ihnen gesuchte Seite nicht finden.',
    home: 'Startseite',
  },

  various: {
    exhausted: 'Erschöpft',
    datapatient: 'Patientendaten',
    center: 'Zentrumsdetails',
    expiredon: 'Verfallsdatum:',
    synclist: 'Synchronisierte Elemente',
    debt: 'Schuld',
    drug: 'Medikament',
    produced: 'Produziert',
    sell: 'Verkauft',
    provided: 'Bereitgestellt',
    total: 'Gesamt',
    date: 'Datum',
    Details: 'Details',
    event: 'Ereignis',
    units: 'Einheiten',
    production: 'Produktion',
    sell2: 'Verkauft',
    add: 'Beitrag hinzufügen',
    historic: 'Historisch',
    historic2: 'Historische Rückverfolgung',
    ns: 'Seriennummer',
    available: 'Verfügbar',
    next: 'Demnächst',
    recomended: 'Empfohlen',
    notrecomended: 'Nicht empfohlen',
    register: 'Registrierung von Lieferungen',
    registerdate: 'Lieferdatum',
    from: 'Von',
    to: 'Bis',
    datapicker: 'Datumsauswahl für Bericht',
    download: 'Herunterladen',
    tpills: 'Gesamttabletten',
    tneed: 'Rest',
    rbox: 'Boxen',
    result: 'Ergebnisse',
    predetermlist: 'Liste der Standard-Dosierungen',
    deletepredet: 'Möchten Sie die ausgewählte Standard-Dosierung wirklich löschen?',
    no_verifi: 'Nicht überprüft',
    verifi: 'Überprüft',
    installation: 'Meine Installation',
    exportamts: 'ATMS exportieren',
    confirmdose: 'Möchten Sie die Dosierung registrieren?',
    whynot: 'Abbruchgrund',
  },
  tooltip: {
    refill: 'Nachfüllbericht',
    label: 'Etiketten drucken',
    edit: 'Produktion bearbeiten',
    grouped: 'Gruppierte Medikationsbericht',
    report1: 'Patienten-Medikationsbericht',
    delivery: 'Lieferbeleg',
    archived: 'Archiviert',
    delete: 'Produktion löschen',
    generateproduction: 'Produktion generieren',
    repackaging: 'Umpackproduktion',
    start: 'Produktion starten',
    list: 'Patientenliste',
    add: 'Neuer Patient',
    listposology: 'Dosierung pro Patient',
    official: 'Offizieller Dosierungsbericht',
    activate: 'WhatsApp aktivieren/deaktivieren',
    consent: 'Informierte Einwilligung',
    alllabel: 'Alle',
    changes: 'Änderungsbericht',
    sevem: 'Verkauf abschließen',
    new: 'Neue Aufgabe',
    copy: 'Kopieren',
    insert: 'Einfügen',
    newparameter: 'Neuer Parameter',
    center: 'Neues Zentrum',
  },
  import: {
    e_title: 'Achtung! Die importierte Datei könnte falsch sein',
    e_dialog: 'Es sieht so aus, als hätten wir eine Datei aus dem Wohnheim importiert:',
    e_dialog1: 'anstatt aus dem Wohnheim:',
    e_dialog2: 'Bitte überprüfen und bestätigen Sie die Situation.',
    i_title: 'Datei importieren',
    i_type: 'Dateiformat',
    i_drop: 'Datei auswählen',
    i_dropb:
      'Sie können die Datei hier ablegen oder auf Ihrem Computer danach suchen, um sie auszuwählen.',
    0: 'Resiplus (*.txt)',
    1: 'Resiplus Multi (*.txt)',
    2: 'Resiplus (*.xls)',
    3: 'Resiplus Multi (*.xls)',
    4: 'Sanitas (*.pdf)',
    5: 'DomusVi (*.txt)',
    6: 'DomusVi (*.pdf)',
    7: 'Pill Device (*.json)',
    8: 'Cretem Atms (*.xml)',
    9: 'Cretem Atms Multi (*.zip)',
    10: 'Jvm Oncube (*.txt)',
    11: 'Jvm Oncube Multi (*.zip)',
    12: 'Ecap (*.txt)',
  },
  module_: {
    title_: 'Modul nicht verfügbar mit der abonnierten Lizenz',
    subtitle_: 'Kontaktieren Sie die Vertriebsabteilung, um eine Vorführung anzufordern!',
    return_: 'Zurück zur App',
  },
  permit: {
    permit: 'Berechtigungen verwalten',
  },
  permissionsgroup: {
    1: 'Medikamentenkatalog',
    2: 'Patientenverwaltung',
    3: 'Patientendosierung',
    4: 'Produktionsmodul',
    5: 'Importe',
    6: 'KI Mery',
    7: 'Rückverfolgbarkeit',
    8: 'Benutzerverwaltung',
    9: 'Zentrumsverwaltung',
    10: 'Apothekendaten verwalten',
    11: 'Medikamentenabgabe',
    12: 'Berichte',
  },
  permissions: {
    1: 'Medikamentenkatalog aktivieren',
    2: 'Medikamente bearbeiten',
    3: 'Patientendaten anzeigen',
    4: 'Patientendaten bearbeiten',
    5: 'Elektronisches Rezept anzeigen',
    6: 'Elektronisches Rezept hinzufügen oder entfernen',
    7: 'Synchronisation anzeigen',
    8: 'Synchronisation hinzufügen oder entfernen',
    9: 'Patientenverbrauch anzeigen',
    10: 'Patientenverbrauch bearbeiten',
    11: 'Dosierung aktivieren',
    12: 'Dosierung bearbeiten',
    13: 'Produktionsmodul aktivieren',
    14: 'Produktion erstellen',
    15: 'Produktion senden / Manueller Blisterabschnitt',
    16: 'Produktion bearbeiten',
    17: 'Produktion löschen',
    18: 'Importmodul aktivieren',
    19: 'Dateien ins System importieren',
    20: 'KI Mery aktivieren',
    21: 'Geplante Aufgaben anzeigen',
    22: 'Aufgaben hinzufügen',
    23: 'Gesundheitskarten anzeigen',
    24: 'Gesundheitskarten einfügen',
    25: 'Verfügbare anzeigen',
    26: 'Dosierung / Rezeptkreuzung anzeigen',
    27: 'Verkaufsparameter aktivieren',
    28: 'Verkaufsparameter erstellen',
    29: 'Patientenbereich aktivieren',
    30: 'Dosissteuerungsmodul aktivieren',
    31: 'Medikamentenausgabe an Patienten',
    32: 'Rückverfolgbarkeit aktivieren',
    33: 'Benutzerverwaltung aktivieren',
    34: 'Benutzer bearbeiten',
    35: 'Zentrumsverwaltung aktivieren',
    36: 'Zentren bearbeiten',
    37: 'Eigene Einrichtung aktivieren',
    38: 'Eigene Einrichtung bearbeiten',
    39: 'Berichterstattung aktivieren',
    40: 'Produktionsbericht',
  },
  control_takes: {
    1: 'Nicht erforderlich',
    2: 'Medikament fehlt',
    3: 'Medikament beschädigt',
    4: 'Patient lehnt ab',
    5: 'Andere',
    cause_not_take: 'Grund',
    6: 'Falsche Uhrzeit',
    7: 'Änderung in der Medikation',
    8: 'Hinzufügen',
    9: 'Entfernen',
    10: 'Einnahme registrieren',
  },
  home: {
    home: 'Startseite',
    title: 'Vereinfache deine Medikation',
    titletex:
      'Willkommen an der Spitze des Medikamentenmanagements. Unsere Software für Blisterzentren definiert deine Beziehung zur Gesundheit neu. Vergiss die Komplikationen bei der Vorbereitung deiner Medikamente. Mit unserer intuitiven und personalisierten Lösung wird die Einhaltung der Behandlung zu einer einfachen und effizienten Erfahrung.',
    available: 'Verfügbar auf',
    benefits: 'Vorteile des Blisterzentren',
    titlebenefit1: 'Erinnerung an Einnahme',
    benefit1:
      'Optimiere deine Routine mit unserer App, die dir präzise Erinnerungen für jede Einnahme bietet. Vergiss die Sorgen über vergessene Einnahmen und sichere dein Wohlbefinden. Zusätzlich kannst du die Unverträglichkeiten und Wechselwirkungen zwischen deinen Medikamenten für eine umfassende und sichere Gesundheitsverwaltung überprüfen.',
    titlebenefit2: 'Persönlicher Service',
    benefit2:
      'Erlebe einen neuen Standard an Komfort in deiner Gesundheitspflege. Mit IDOOSE erhältst du deine Medikation in sorgfältig vorbereiteten Dosen von hochqualifizierten Apothekern. Wir widmen uns deinem Wohlbefinden, indem wir dir eine unkomplizierte Erfahrung bieten, Qualität liefern und jede Einnahme vereinfachen.',
    titlebenefit3: 'Wir bereiten deine Medikation vor',
    benefit3:
      'Auf Wiedersehen, komplizierte Boxenvorbereitung. Mit unserem System für aufeinanderfolgende Einzeldosen ist die Einnahme deiner Medikation so einfach wie öffnen und loslegen. Jede Dosis enthält detaillierte Informationen, einschließlich des Beipackzettels, um dir Komfort und Klarheit bei jedem Schritt deiner Behandlung zu bieten.',
    appidoose: 'Mit IDOOSE vergisst du nie deine Medikation.',
    appidoosetext:
      'Lade die App herunter, registriere dich und wir bringen dich mit deiner nächstgelegenen Apotheke in Kontakt.',
    listposology: 'Dosierung pro Patient',
    design: 'Entworfen für dich',
    partner: 'Möchtest du mit uns zusammenarbeiten?',
    partner2: 'WERDE PARTNER',
    partnertext:
      'Wir zeichnen uns als unbestrittene Branchenführer bei der Anwendung von künstlicher Intelligenz im Blisterzentren aus. Wenn du uns wählst, garantierst du die Effizienz deiner Produktionen und eliminierst vollständig jedes Risiko von Fehlern im Produktionsprozess. Vertraue auf unsere Erfahrung, um deine Operationen auf höchste Präzision und Sicherheit zu bringen.',
    prices: 'PREISE UND PLÄNE',
    licence: 'Lizenz',
    patient: 'Patientenverwaltung',
    production: 'Produktionsmodul',
    import: 'Import von Robot-Dateien',
    blister: 'Manuelle Blisterproduktion',
    update: 'Aktualisierungen',
    support: 'Technischer Support',
    patient30: 'Bis zu 50 Patienten',
    patient31: 'Unbegrenzte Patienten',
    rx: 'Anbindung an elektronisches Rezept',
    inform: 'Berichterstattung über Dosierung der autonomen Gemeinschaften',
    warning: 'Änderungen im elektronischen Rezept',
    next: 'Nächste Abgaben',
    mach: 'Dosierung/Rezeptkreuzung',
    sell: 'Verkaufsmodul',
    question: 'Hast du noch Fragen?',
    question2:
      'Um dir die beste Beratung zu bieten, teile uns mit, ob du eine Apotheke, ein Krankenhaus oder ein Labor vertrittst. Auf diese Weise können wir dir personalisierte Informationen entsprechend den Besonderheiten deines Sektors zur Verfügung stellen.',
    contact: 'Kontaktiere uns',
    reserved: 'Alle Rechte vorbehalten, erstellt von',
    forgotpasswordintro:
      'Gib die E-Mail-Adresse ein, die mit deinem Konto verknüpft ist, und wir senden dir einen Link zum Zurücksetzen deines Passworts.',
    request: 'Anfrage senden',
    returnto: 'Zurück zur Anmeldeseite',
    choose: 'Benötigen Sie einen Dosierroboter?',
    choose1: 'Entdecken Sie unsere Roboter für individuelle Dosiersysteme',
    choose2:
      'Wir bieten eine breite Palette von Robotern für individuelle Dosiersysteme an, um Ihren spezifischen Anforderungen gerecht zu werden. Wir bieten Modelle, die je nach Produktionsvolumen, Automatisierungsgrad und gewünschtem Format gestaltet sind, sei es Blister oder Beutel. Darüber hinaus verfügen wir über Roboter, die mit der Oculus-Technologie ausgestattet sind, welche die Überprüfung und Kontrolle innerhalb des Geräts integrieren.',

    siglas: 'Blisterzentren',
    robottitle: 'Entdecken Sie unser Sortiment an',
    robotdescription:
      'Wenn Sie die Automatisierung bei der Zubereitung von Blisterzentren optimieren möchten, sind unsere Roboter Ihr idealer Partner. Wir bieten eine breite Palette von Blisterzentren-Robotern an, von hochautomatisierten bis hin zu weniger automatisierten, um uns an Ihre spezifischen Anforderungen anzupassen. Dank unserer fortschrittlichen Technologie und der universellen FSP-Trichter, die wir anbieten, können Sie die Abhängigkeit von der Kalibrierung signifikant reduzieren. Darüber hinaus sind unsere Roboter mit dem integrierten OCULUS-System ausgestattet, das Ihnen detaillierte fotografische Beweise für jede Produktion sowie umfassende Analysen aller Dosen liefert.',
    catalog: 'Katalog herunterladen',
    diff: 'Hauptvorteile unserer Blisterzentren-Roboter',
    diff1: 'Universelle FSP',
    diff1a:
      'Mit den universellen FSP können Sie die benötigte Medikation zum richtigen Zeitpunkt ohne Komplikationen und Kalibrierungen verwenden. Unsere innovative Technologie garantiert die Verwendung jeder gewünschten Art von Medikament, unabhängig von Form oder Größe. Sie werden eine größere Freiheit in Ihrer Produktion genießen.',
    diff2: 'Hohe Rotation',
    diff2a:
      'Um die ständige Verfügbarkeit der am häufigsten verwendeten Moleküle sicherzustellen, führen wir eine sorgfältige Kalibrierung durch. Unsere Experten werden eine gründliche Untersuchung durchführen, um diese Auswahl zu validieren, und somit eine präzise Kalibrierung und eine effiziente Automatisierung gewährleisten. Dieser Ansatz ermöglicht es uns, eine optimale Automatisierung zu erreichen.',
    diff3: 'Vollständige Rückverfolgbarkeit',
    diff3a:
      'Mit unseren Robotern ist die Verwaltung Ihrer Medikation einfacher als je zuvor. Scannen Sie einfach den Sevem-Code, um Chargennummer und Ablaufdatum jeder produzierten Dosis zu erfassen. Dieser schnelle und effiziente Prozess ermöglicht es Ihnen, Ihre Medikation genau zu verfolgen und eine präzise und sichere Kontrolle zu gewährleisten.',
    robotnews: 'OCULUS-System',
    robotnews2:
      'Bei Robotik haben wir die OCULUS-Technologie entwickelt, die die gesamte Produktion mithilfe künstlicher Vision überprüft und analysiert. Mit OCULUS können Sie sich die Zeit sparen, die normalerweise für die manuelle Überprüfung der Produktion aufgewendet wird; unsere Technologie erledigt dies für Sie. Darüber hinaus hinterlässt sie immer fotografische Beweise für alles, was mit Ihrem Robotik-Roboter produziert wurde. Zusammenfassend bietet Ihnen OCULUS absolute Sicherheit.',
    discover: 'Entdecken Sie die Eigenschaften jedes Blisterzentren-Roboters',
    discover2: 'AUSWÄHLEN',
    tablerobots: 'Der ideale Blisterzentren-Roboter für alle Anforderungen',
    footer:
      'Die beste Einzeldosis-Blisterzentren-Lösung auf dem Markt. Wir sind Experten darin, künstliche Intelligenz in Managementprozessen zu implementieren.',
    corfirmform: 'Formular erhalten!',
    corfirmform2:
      'Unser Team wird die bereitgestellten Informationen überprüfen und sich bald mit Ihnen in Verbindung setzen. Vielen Dank für Ihr Interesse!',
    telefon: 'Telefon',
  },

  myprofile: {
    edit: 'Bearbeiten',
    user: 'Benutzer',
    name: 'Name und Nachname',
    mail: 'E-Mail-Adresse',
    number: 'Telefonnummer',
    country: 'Land',
    state: 'Bundesland',
    city: 'Stadt',
    address: 'Adresse',
    zip: 'Postleitzahl',
    company: 'Unternehmen',
    role: 'Rolle',
    save: 'Änderungen speichern',
    delete: 'Benutzer löschen',
    verify: 'E-Mail überprüft',
    verifyinfo:
      'Durch Deaktivieren dieser Option erhält der Benutzer automatisch eine E-Mail zur Überprüfung.',
    banned: 'Deaktiviert',
    apply: 'Konto deaktivieren',
    new: 'Benutzer erstellen',
    permit: 'Erlaubte Dateitypen: *.jpeg, *.jpg, *.png, *.gif',
    max: 'max. Größe von',
    management: 'Verwaltung',
    search: 'Suchen...',
    list: 'Benutzerliste',
    changecenter: 'Zentrum ändern',
    resetpass: 'Passwort zurücksetzen',
    confirmdelete: 'Möchten Sie den ausgewählten Benutzer wirklich löschen?',
    dense: 'Verdichtet',
    confirmreset: 'E-Mail erfolgreich gesendet',
    quick: 'Schnellbearbeitung von Berechtigungen',
  },
  pages: {
    pages: 'Seiten',
    about: 'Über uns',
    contact: 'Kontaktiere IDOOSE',
    inicio: 'Startseite',
    info: 'Information',
    community: 'Gemeinschaft',
    post: 'Beiträge',
    robot: 'Blisterzentren',
  },
  about: {
    who: 'Wer sind wir?',
    about: 'Über uns',
    contact: 'Kontaktiere IDOOSE',
    inicio: 'Startseite',
    info: 'Information',
    community: 'Gemeinschaft',
    post: 'Beiträge',
    whotitle: 'Wer',
    are: 'sind wir?',
    mision:
      'Unsere Mission ist es, die Gesundheit und das Wohlbefinden unserer Patienten zu verbessern,',
    mision2: 'indem wir Medikamente in Einzeldosen präzise vorbereiten und so eine sichere',
    mision3: 'und effektive Verabreichung auf individueller Basis gewährleisten.',
    whatis: 'Was ist IDOOSE?',
    text: 'Wir sind mehr als nur ein Medikamentenverwaltungsprogramm. Wir streben danach, eine positive Veränderung in der Gesundheit der Menschen zu fördern. Mit dem fortschrittlichsten und zugänglichsten Verwaltungsprogramm bemühen wir uns, Tools bereitzustellen, die nicht nur die Medikamenteneinnahme vereinfachen, sondern auch die Gemeinschaftsbindung und das allgemeine Wohlbefinden fördern. Dank unserer KI bieten wir den sichersten und zuverlässigsten Service auf dem Markt für Medikamentenvorbereitung. Bei IDOOSE bieten wir einen Weg zu einem gesünderen, einfacheren und sichereren Leben.',
  },
  contact: {
    where: 'Wo',
    find: 'findest du uns?',
    contact: 'Zögern Sie nicht, uns zu kontaktieren. Wir helfen Ihnen gerne weiter.',
    name: 'Name',
    email: 'E-Mail',
    subjet: 'Betreff',
    message: 'Schreiben Sie hier Ihre Nachricht.',
    submit: 'Absenden',
  },
  rulesimport: {
    list: 'Importparameter',
    form: 'Verkaufsbericht',
    step1: 'Datei auswählen',
    step2: 'Konfliktüberprüfung',
    step3: 'Datei hochladen',
    importcorrect: 'Korrekt',
    sugest: 'Empfehlung',
    new: 'Neu',
    noconflicts: 'Datei erfolgreich validiert, es wurden keine Konflikte festgestellt.',
    sureimport:
      'Die Datenbank wurde aktualisiert. Klicken Sie auf Abschließen, um den Import abzuschließen.',
    importcomplete: 'Der Import wurde erfolgreich abgeschlossen.',
  },
  calendar: {
    title: 'Kalender',
    event: 'Neues Ereignis',
    month: 'Monat',
    week: 'Woche',
    day: 'Tag',
    agenda: 'Agenda',
    today: 'Heute',
    new: 'Ereignis hinzufügen',
    new2: 'Titel',
    description: 'Beschreibung',
    allday: 'Ganztägig',
    start: 'Startdatum',
    end: 'Enddatum',
  },
  tablerobots: {
    tolvas: 'Anzahl Trichter',
    univers: 'Universelle FSP',
    width: 'Breite (cm)',
    height: 'Höhe (cm)',
    depth: 'Tiefe (cm)',
  },
  yup: {
    yup_min: 'Zu kurz!',
    yup_max: 'Zu lang!',
    yup_required: 'Feld erforderlich!',
    yup_email: 'E-Mail muss eine gültige E-Mail-Adresse sein!',
    yup_phone: 'Telefonnummer darf nur Ziffern enthalten!',
  },
  cookie_consent: {
    acept: 'Akzeptieren',
    reject: 'Abfall',
    message:
      'Unsere Website verwendet Cookies, um den Datenverkehr zu analysieren und unsere Dienste zu verbessern.',
  },
  form_geometric: {
    1: 'Länglich',
    2: 'Blase',
    3: 'Ring',
    4: 'Bogen',
    5: 'Kugel',
    6: 'Bikonvex',
    7: 'Kapsel',
    8: 'Patrone',
    9: 'Zylindrisch',
    10: 'Konkav',
    11: 'Quadrat',
    12: 'Elliptisch',
    13: 'Kugelförmig',
    14: 'Gas',
    15: 'Inhalator',
    16: 'Träne',
    17: 'Flüssigkeit',
    18: 'Apfel',
    19: 'Länglich',
    20: 'Achteckig',
    21: 'Oval',
    22: 'Staub',
    23: 'Rechteckig',
    24: 'Rund',
    25: 'Raute',
    26: 'Über',
    27: 'Dreieckig',
    28: 'Phiole',
  },
  form_size: {
    0: 'Undefiniert',
    1: '2 mm (Mikro)',
    2: '4 mm (Klein)',
    3: '8 mm (Mittel)',
    4: '12 mm (Groß)',
    5: '18.0 mm (Sehr Groß)',
    6: '23.3 mm (Extra Groß)',
  },
  form_desc: {
    form_geometric: 'Geometrische Form',
    form_size: 'Größe',
    form_slot: 'Geschlitzt',
  },
  termsconditions: {
    title: 'Nutzungsbedingungen von IDOOSE',
    subtitle: 'Willkommen bei IDOOSE',
    parraf1:
      'Vielen Dank, dass Sie sich für IDOOSE entschieden haben. Bitte lesen Sie diese Nutzungsbedingungen sorgfältig durch, bevor Sie unsere Software verwenden. Durch die Nutzung von IDOOSE akzeptieren Sie diese Nutzungsbedingungen. Wenn Sie mit irgendeinem dieser Bedingungen nicht einverstanden sind, nutzen Sie unsere Software nicht.',
    subtitle2: '1. Geltungsbereich des Vertrags und Annahme',
    subtitle3: '1.1. Im Vertrag abgedeckte Dienstleistungen',
    parraf2:
      'Dieser Vertrag gilt für idoose.com und jede andere Software, Website oder Dienstleistung, die von IDOOSE ROBOTIK SL bereitgestellt wird (zusammenfassend die "Dienste").',
    subtitle4: '1.2. Annahme dieses Vertrags',
    parraf3:
      'Durch die Nutzung der Dienste von IDOOSE akzeptieren Sie diese Nutzungsbedingungen ohne Änderungen. Wenn Sie nicht einverstanden sind, nutzen Sie die Dienste nicht.',
    subtitle5: '1.3. Änderung des Vertrags',
    parraf4:
      'IDOOSE ROBOTIK SL behält sich das Recht vor, diese Nutzungsbedingungen jederzeit zu ändern. Wir werden Sie über Änderungen per E-Mail oder über unsere Website informieren. Ihre fortgesetzte Nutzung der Dienste nach solchen Änderungen stellt Ihre Zustimmung zu diesen Änderungen dar.',
    subtitle6: '2. Nutzung der Dienste und Einschränkungen',
    subtitle7: '2.1. Nutzung der Software',
    parraf5:
      'IDOOSE ist lizenziert, nicht verkauft. Sie müssen alle technischen Einschränkungen der Software einhalten und dürfen nicht: • Technische Einschränkungen der Software umgehen • Die Software dekompilieren, disassemblieren oder zurückentwickeln, außer wenn dies gesetzlich zulässig ist. • Mehr Kopien der Software anfertigen als in diesem Vertrag angegeben. • Die Software veröffentlichen, damit andere sie kopieren können. • Die Software vermieten, verleasen oder verleihen. • Die Software oder diesen Vertrag an Dritte übertragen.',
    subtitle8: '2.2. Pflichten des Nutzers',
    parraf6:
      'Sie verpflichten sich, die Dienste verantwortungsvoll zu nutzen und keine Handlungen vorzunehmen, die IDOOSE ROBOTIK SL oder anderen Nutzern schaden könnten. Dies umfasst unter anderem: • Die Dienste nicht für illegale oder nicht autorisierte Aktivitäten zu nutzen. • Den Betrieb der Dienste nicht zu stören oder auf die Dienste durch unbefugte Methoden zuzugreifen. • Keine persönlichen Informationen anderer Nutzer ohne deren Zustimmung zu sammeln. • Keine Viren oder andere schädliche Software hochzuladen oder zu verbreiten.',
    subtitle9: '3. IDOOSE-Konto',
    subtitle10: '3.1. Kontoerstellung',
    parraf7:
      'Um auf bestimmte Dienste zugreifen zu können, benötigen Sie ein IDOOSE-Konto. Sie sind dafür verantwortlich, die Vertraulichkeit Ihrer Kontoinformationen und Ihres Passworts zu wahren.',
    subtitle11: '3.2. Nutzung des Kontos',
    parraf8:
      'Sie sind für alle Aktivitäten verantwortlich, die unter Ihrem Konto stattfinden. Sie müssen IDOOSE ROBOTIK SL unverzüglich über jede unbefugte Nutzung Ihres Kontos oder jede andere Sicherheitsverletzung informieren.',
    subtitle12: '4. Inhalt',
    subtitle13: '4.1. Eigentum am Inhalt',
    parraf9:
      'Die Inhalte, die Sie über die Dienste hochladen oder übermitteln, bleiben Ihr Eigentum. IDOOSE ROBOTIK SL erhebt keinen Anspruch auf das Eigentum an Ihren Inhalten.',
    subtitle14: '4.2. Verantwortlichkeit für den Inhalt',
    parraf10:
      'Sie sind allein verantwortlich für die Inhalte, die Sie über die Dienste hochladen, speichern oder übermitteln. Sie erklären und garantieren, dass Sie alle erforderlichen Rechte besitzen, um diese Inhalte hochzuladen, und dass Ihre Inhalte keine Rechte Dritter verletzen.',
    subtitle15: '4.3. Überprüfung und Löschung von Inhalten',
    parraf11:
      'IDOOSE ROBOTIK SL behält sich das Recht vor, Inhalte, die auf ihren Servern hochgeladen wurden, zu überprüfen und alle Inhalte zu löschen, die nach eigenem Ermessen gegen diese Nutzungsbedingungen oder geltendes Recht verstoßen.',
    subtitle16: '5. Kündigung der Dienste',
    subtitle17: '5.1. Durch den Nutzer',
    parraf12:
      'Sie können Ihr IDOOSE-Konto jederzeit über die Verwaltung Ihres Kontos kündigen. Die Kündigung wird am Ende Ihres Abrechnungszeitraums wirksam.',
    subtitle18: '5.2. Durch IDOOSE ROBOTIK SL',
    parraf13:
      'IDOOSE ROBOTIK SL behält sich das Recht vor, Ihr Konto oder Ihren Zugang zu den Diensten jederzeit mit oder ohne Grund und ohne vorherige Ankündigung zu kündigen.',
    subtitle19: '6. Anwendbares Recht',
    parraf14:
      'Dieser Vertrag unterliegt den Gesetzen Spaniens, ohne Rücksicht auf kollisionsrechtliche Grundsätze. Alle Streitigkeiten, die sich aus diesem Vertrag ergeben, unterliegen der ausschließlichen Gerichtsbarkeit der spanischen Gerichte.',
    subtitle20: '7. Kontaktdaten',
    parraf15: 'Bei Fragen zu diesem Vertrag können Sie uns unter info@idoose.com kontaktieren.',
  },
  privacy: {
    title: 'Datenschutzrichtlinie',
    subtitle: 'IDOOSE ROBOTIK SL',
    parraf1:
      'Bei IDOOSE ROBOTIK SL mit Sitz in Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564 und CIF B56740137 sind wir verpflichtet, die Privatsphäre und Sicherheit unserer Nutzer zu schützen. Diese Datenschutzrichtlinie beschreibt, wie wir Ihre persönlichen Informationen sammeln, verwenden und schützen, wenn Sie unsere Software nutzen.',
    subtitle2: '1. Informationen, die wir sammeln',
    parraf2:
      'Wir können die folgenden Arten von Informationen sammeln: Personenbezogene Identifikationsinformationen: Name, Adresse, Telefonnummer, E-Mail-Adresse und alle anderen Informationen, die Sie freiwillig bereitstellen. Nutzungsinformationen der Software: Daten darüber, wie Sie mit unserer Software interagieren, einschließlich der Funktionen, die Sie nutzen, und der Nutzungshäufigkeit. Technische Informationen: Technische Daten über Ihr Gerät, wie IP-Adresse, Browsertyp, Betriebssystem und Spracheinstellungen.',
    subtitle3: '2. Nutzung der Informationen',
    parraf3:
      'Wir verwenden die gesammelten Informationen für: Bereitstellung, Betrieb und Wartung unserer Software. Verbesserung, Personalisierung und Erweiterung unserer Software. Verstehen und Analysieren, wie Sie unsere Software nutzen. Entwicklung neuer Funktionen, Produkte und Dienstleistungen. Kommunikation mit Ihnen, entweder direkt oder über einen unserer Partner, einschließlich Kundenservice, Bereitstellung von Updates und anderen Informationen im Zusammenhang mit der Software sowie für Marketing- und Werbezwecke. Abwicklung Ihrer Transaktionen und Verwaltung Ihres Kontos. Versenden regelmäßiger E-Mails.',
    subtitle4: '3. Schutz Ihrer Informationen',
    parraf4:
      'Wir implementieren verschiedene Sicherheitsmaßnahmen, um die Sicherheit Ihrer persönlichen Informationen zu gewährleisten, wenn Sie Ihre persönlichen Informationen eingeben, übermitteln oder darauf zugreifen. Bitte beachten Sie jedoch, dass keine Methode der Übertragung über das Internet oder der elektronischen Speicherung 100% sicher ist.',
    subtitle5: '4. Weitergabe Ihrer Informationen',
    parraf5:
      'Wir verkaufen, tauschen oder übertragen Ihre personenbezogenen Identifikationsinformationen nicht an Dritte. Dies schließt vertrauenswürdige Dritte, die uns beim Betrieb unserer Software, der Führung unseres Geschäfts oder der Betreuung Ihrer Person unterstützen, nicht aus, solange diese Parteien zustimmen, diese Informationen vertraulich zu behandeln. Wir können Ihre Informationen auch offenlegen, wenn wir glauben, dass die Offenlegung angemessen ist, um das Gesetz zu erfüllen, unsere Website-Richtlinien durchzusetzen oder unsere Rechte, unser Eigentum oder unsere Sicherheit zu schützen.',
    subtitle6: '5. Ihre Rechte',
    parraf6:
      'Sie haben das Recht auf Zugang, Berichtigung, Löschung und Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten. Um diese Rechte auszuüben, können Sie uns per E-Mail an info@idoose.com oder per Post an folgende Adresse kontaktieren: Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564.',
    subtitle7: '6. Änderungen dieser Datenschutzrichtlinie',
    parraf7:
      'Wir behalten uns das Recht vor, diese Datenschutzrichtlinie jederzeit zu aktualisieren. Wir werden Sie über Änderungen durch die Veröffentlichung der neuen Datenschutzrichtlinie auf dieser Seite informieren. Es wird empfohlen, diese Datenschutzrichtlinie regelmäßig zu überprüfen, um über etwaige Änderungen informiert zu bleiben.',
    subtitle8: '7. Kontakt',
    parraf8:
      'Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, können Sie uns kontaktieren: IDOOSE ROBOTIK SL Calle Castillo de Moratalla 45, Lorquí, Murcia, CP 30564 E-Mail: info@idoose.com',
  },
  payments: {
    title: 'Kaufzusammenfassung',
    totalFacturado: 'Gesamtabrechnung',
    taxes:'Zuzüglich der anfallenden Steuern',
    compra: 'kaufen',
    securitycard: 'Sichere Zahlung per Kreditkarte',
    encrypted: 'Dies ist eine sichere 128-Bit-SSL-verschlüsselte Zahlung',
    subscription: 'Abonnement',
    subrealizada:'Erfolgreiches Planabonnement!',
    manage:'Verwalten Sie Ihre Rechnungsinformationen',
    mes:'/ Monat'
  },
};

export default de;
